import React from 'react'

import { connect } from 'react-redux'

import Alert from 'react-bootstrap/Alert'

const Alerts = ({ alerts }) => {
  return (
    <div className='alerts'>
      {alerts?.map((alert) => (
        <Alert key={alert.id} variant={alert.type}>
          {alert.message}
        </Alert>
      ))}
    </div>
  )
}

const mapStateToProps = (state) => ({
  alerts: state.alert,
})

const connectedAlert = connect(mapStateToProps)(Alerts)
export { connectedAlert as Alerts }
