import { contractHistoryService } from '../_services'

import { alertConstants } from '../_constants'
import { alertActions } from './alert.actions'

export const fetchContractsHistories = (page = 1) => async (dispatch) => {
  try {
    const histories = await contractHistoryService.findAll(page)
    dispatch({
      type: 'contractsHistories/itemsLoaded',
      payload: histories,
    })
  } catch (err) {
    console.error(`Error : ${err}`)

    dispatch({
      type: 'contractsHistories/fetchError',
      payload: null,
    })

    dispatch(
      alertActions.setAlert("Une erreur s'est produite.", alertConstants.ERROR)
    )
  }
}
