import { alertConstants } from '../_constants'

const initialState = []

export function alert(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case alertConstants.SET_ALERT:
      return [...state, payload]
    case alertConstants.REMOVE_ALERT:
      return state.filter((alert) => alert.id !== payload)
    case alertConstants.CLEAR_ALERTS:
      return initialState
    default:
      return state
  }
}
