import React from 'react'

import './StatsCard.scss'

export const StatsCard = ({ count, desc, children }) => {
  return (
    <div className='stats-card'>
      <div className='stats-card__count'>{count}</div>

      <div className='stats-card__desc'>{desc}</div>

      <div className='stats-card__btns'>{children}</div>
    </div>
  )
}
