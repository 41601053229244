import React from 'react'

export const PageNotFound = () => {
  return (
    <>
      <h1>Page non trouvée</h1>

      <p>Désolé, cette page n'existe pas.</p>
    </>
  )
}
