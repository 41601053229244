import styles from './ContractStyles'

const jsonView = {
  type: 'page',
  items: [
    {
      type: 'view',
      styles: {
        margin: 10,
        marginLeft: 20,
        padding: 10,
      },
      items: [
        {
          type: 'text',
          styles: {
            ...styles.textContent,
            marginTop: 10,
            textAlign: 'center',
          },
          content:
            'Appendix I - RIDER TO THE AGREEMENT WITHOUT MODIFICATION OF CONTRACTUAL PRICE',
        },

        
        {
          type: 'view',
          styles: {
            border: '1pt solid #000000',
            padding: '5pt',
          },
          items: [
            {
              type: 'text',
              styles: {
                ...styles.textContent,
                fontFamily: 'Helvetica-Bold',
                textAlign: 'center',
              },
              content:
                'Table of changes during the cruise, representing a rider to the Agreement without impact on the contractually agreed price',
            },

            {
              type: 'text',
              styles: {
                ...styles.textContent,
                fontFamily: 'Helvetica-Bold',
                textDecoration: 'underline',
              },
              content: '1. Change/s of port/s of call',
            },

            {
              type: 'text',
              styles: {
                fontSize : 10,
                fontStyle: 'italic',
              },
              content:
                '(Please indicate the port/s of call which have been cancelled, replaced and/or added and initial - Client and Captain)',
            },

            {
              type: 'view',
              styles: {
                flexDirection: 'row',
              },
              items: [
                {
                  type: 'view',
                  styles: {
                    width: '80%',
                  },
                  items: [],
                },

                {
                  type: 'view',
                  styles: {
                    width: '20%',
                  },
                  items: [
                    {
                      type: 'text',
                      styles: {
                        ...styles.textContent,
                        fontFamily: 'Helvetica-Bold',
                        fontStyle: 'italic',
                        textAlign: 'center',
                      },
                      content: 'Initial here',
                    },
                  ],
                },
              ],
            },

            {
              type: 'view',
              repeated: true,
              findRepeated: 'changeOfPorts_content',
              styles: {
                flexDirection: 'row',
              },
              items: [
                {
                  type: 'view',
                  styles: {
                    width: '80%',
                  },
                  items: [
                    {
                      type: 'text',
                      styles: {
                        ...styles.textContent,
                      },
                      content: '__changeOfPorts_content__',
                    },
                  ],
                },

                {
                  type: 'view',
                  styles: {
                    width: '10%',
                  },
                  items: [
                    {
                      type: 'view',
                      styles: {
                        border: '1pt solid #000000',
                        width: 35,
                        height: 20,
                      },
                      items: [],
                    },
                  ],
                },

                {
                  type: 'view',
                  styles: {
                    width: '10%',
                  },
                  items: [
                    {
                      type: 'view',
                      styles: {
                        border: '1pt solid #000000',
                        width: 35,
                        height: 20,
                      },
                      items: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },

    {
      type: 'view',
      attrs: {
        break: true,
      },
      styles: {
        border: '1pt solid #000000',
        padding: '5pt',
        margin: 10,
        marginLeft: 20,
        padding: 10,
      },
      items: [
        {
          type: 'text',
          styles: {
            ...styles.textContent,
            fontFamily: 'Helvetica-Bold',
            textAlign: 'center',
          },
          content:
            'Table of changes during the cruise, representing a rider to the Agreement without impact on the contractually agreed price',
        },

        {
          type: 'text',
          styles: {
            ...styles.textContent,
            fontFamily: 'Helvetica-Bold',
            textDecoration: 'underline',
          },
          content: '2. Initial embarkation and embarkations/disembarkations during the voyage',
        },

        {
          type: 'text',
          styles: {
            fontSize : 10,
            fontStyle: 'italic',
          },
          content:
            '(Please indicate the initial embarkation and successive embarkations / disembarkations and initial - Client and Captain for each change)',
        },

        {
          type: 'view',
          repeated: true,
          findRepeated: 'changeOfEmbarkation_initial',
          styles: {},
          items: [
            {
              type: 'text',
              styles: {
                ...styles.textContent,
              },
              content:
                'Initial embarkation of Mr/Ms : __changeOfEmbarkation_initial__',
            },

            {
              type: 'view',
              styles: {
                flexDirection: 'row',
              },
              items: [
                {
                  type: 'view',
                  styles: {
                    width: '80%',
                  },
                  items: [
                    {
                      type: 'text',
                      styles: {
                        ...styles.textContent,
                      },
                      content: 'Port of call : __changeOfEmbarkation_port__',
                    },

                    {
                      type: 'text',
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        'Disembarkations : __changeOfEmbarkation_disembarkations__',
                    },

                    {
                      type: 'text',
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        'Embarkations : __changeOfEmbarkation_embarkations__',
                    },
                  ],
                },

                {
                  type: 'view',
                  styles: {
                    width: '10%',
                  },
                  items: [
                    {
                      type: 'view',
                      styles: {
                        border: '1pt solid #000000',
                        width: 35,
                        height: 20,
                      },
                      items: [],
                    },
                  ],
                },

                {
                  type: 'view',
                  styles: {
                    width: '10%',
                  },
                  items: [
                    {
                      type: 'view',
                      styles: {
                        border: '1pt solid #000000',
                        width: 35,
                        height: 20,
                      },
                      items: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}

export default jsonView
