import { libraryDocsService } from '../_services'

import { alertConstants } from '../_constants'
import { alertActions } from './alert.actions'

export const fetchLibraryDocs = (page = 1) => async (dispatch) => {
  try {
    const libraryDocs = await libraryDocsService.findAll(page)

    dispatch({
      type: 'libraryDocs/itemsLoaded',
      payload: libraryDocs,
    })
  } catch (err) {
    dispatch({ type: 'libraryDocs/fetchError' })
    dispatch(alertActions.setAlert(err, alertConstants.ERROR))
  }
}
