import { box, randomBytes } from "tweetnacl";
import {
  decodeUTF8,
  encodeUTF8,
  encodeBase64,
  decodeBase64,
} from "tweetnacl-util";

export function encrypt(message, publicKey, privateKey) {
  const decodedPrivateKey = decodeBase64(privateKey);
  const decodedPublicKey = decodeBase64(publicKey);

  const nonce = randomBytes(box.nonceLength);
  const messageUint8 = decodeUTF8(message);

  const encrypted = box(
    messageUint8,
    nonce,
    decodedPublicKey,
    decodedPrivateKey
  );

  const fullMessage = new Uint8Array(nonce.length + encrypted.length);
  fullMessage.set(nonce);
  fullMessage.set(encrypted, nonce.length);

  return encodeBase64(fullMessage);
}

export function decrypt(message, publicKey, privateKey) {
  if (message) {
    const decodedPrivateKey = decodeBase64(privateKey);
    const decodedPublicKey = decodeBase64(publicKey);

    const messageAsUint8 = decodeBase64(message);
    const nonce = messageAsUint8.slice(0, box.nonceLength);

    const trueMessage = messageAsUint8.slice(box.nonceLength, message?.length);

    const decrypted = box.open(
      trueMessage,
      nonce,
      decodedPublicKey,
      decodedPrivateKey
    );

    return encodeUTF8(decrypted);
  }
}
