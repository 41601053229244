import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { DefaultLayout } from '../_layouts'

export const DefaultRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <DefaultLayout>
        <Component {...props} />
      </DefaultLayout>
    )}
  />
)
