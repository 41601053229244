import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { companiesService } from "../_services";
import { alertActions, userActions } from "../_actions";
import { alertConstants } from "../_constants";

import { history } from "../_helpers";

import { CompanyEditForm } from "../Admin/companies/components/CompanyEditForm";

export const CompanyEdit = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [company, setCompany] = useState(null);

  const user = useSelector((state) => state.authentication.user);

  useEffect(() => {
    (async () => {
      // Edit mode => need to fetch user
      try {
        const company = await companiesService.find(id);

        setCompany(company);
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    })();
  }, [id]);

  const handleSubmit = async (formData) => {
   /*  const {
      name,
      address1,
      address2,
      zipCode,
      city,
      country,
      MYBAid,
      registrationNumber,
      registrationCountry,
      VATnumber,
      insurance,
      financialGuarantee,
      ecpyId,
      cdtPermit,
      cdcPermit,
    } = formData;
 */
    // Edit
    try {
      formData.id = company.id;
      console.log("company form data", formData);
      /* const updatedCompany = await companiesService.update(formData);

      dispatch(
        alertActions.setAlert(
          "La société a bien été modifiée.",
          alertConstants.SUCCESS
        )
      );

      dispatch(userActions.updateUser(user));

      history.push("/my-account"); */
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }
  };

  return (
    <>
      <h1 className="page-title">{t("Update company")}</h1>

      {company && (
        <CompanyEditForm
          company={company}
          onSubmit={handleSubmit}
          isAddMode={false}
          isUser={true}
        />
      )}
    </>
  );
};
