import axios from "axios";

import { ApiService } from "./api";

import { authHeader } from "../_helpers";

const apiService = new ApiService();
apiService.setApiUrl("/contract_fields");

export const contractFieldService = {
  add,
  //remove,
  find,
  addGroup,
  removeGroup,
};

async function add(item) {
  return apiService.add(item);
}

/* async function remove(item) {
  return apiService.remove(item.id);
}
 */
async function find(id) {
  return apiService.find(id);
}

async function addGroup(data) {
  return apiService.addGroup(data);
}

async function removeGroup(ids) {
  return apiService.removeGroup(ids);
}
