import React from 'react'

import Pagination from 'react-bootstrap/Pagination'

export const JddwPagination = ({ navigateToPage, page = 1, nbPages = 1 }) => {
  const paginationStyle = {
    justifyContent: 'center',
  }

  return (
    <Pagination style={paginationStyle}>
      {page == 1 ? (
        <>
          <Pagination.First disabled />
          <Pagination.Prev disabled />
        </>
      ) : (
        <>
          <Pagination.First onClick={() => navigateToPage(1)} />
        </>
      )}

      {page - 1 > 0 && (
        <>
          <Pagination.Prev onClick={() => navigateToPage(page - 1)} />
          <Pagination.Item onClick={() => navigateToPage(page - 1)}>
            {page - 1}
          </Pagination.Item>
        </>
      )}

      <Pagination.Item active>{page}</Pagination.Item>

      {page + 1 < nbPages && (
        <>
          <Pagination.Item onClick={() => navigateToPage(page + 1)}>
            {page + 1}
          </Pagination.Item>
          <Pagination.Ellipsis />
        </>
      )}

      {page == nbPages ? (
        <>
          <Pagination.Next disabled />
          <Pagination.Last disabled />
        </>
      ) : (
        <>
          <Pagination.Item onClick={() => navigateToPage(nbPages)}>
            {nbPages}
          </Pagination.Item>
          <Pagination.Next onClick={() => navigateToPage(page + 1)} />
          <Pagination.Last onClick={() => navigateToPage(nbPages)} />
        </>
      )}
    </Pagination>
  )
}
