import React from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { useTranslation } from 'react-i18next'

export const ValidationModal = ({ title, text, show, onOptionSelected }) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal show={show} onHide={() => onOptionSelected('no')}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize : "18px"}}>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body dangerouslySetInnerHTML={{ __html: text }}></Modal.Body>

        <Modal.Footer>
          <Button variant='success' onClick={() => onOptionSelected('yes')}>
            {t('Yes')}
          </Button>

          <Button variant='danger' onClick={() => onOptionSelected('no')}>
            {t('No')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
