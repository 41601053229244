import React, { useEffect, useState } from 'react'

import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { CSVLink, CSVDownload } from 'react-csv'

import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { alertActions } from '../../_actions/alert.actions'
import { alertConstants } from '../../_constants'

import { JddwPagination } from '../../_components'

import { history } from '../../_helpers'

import { fetchCompanies, fetchCompany } from '../../_actions/company.actions'
import { companiesService } from '../../_services'

import { Link } from 'react-router-dom'

import { ValidationModal } from '../../_components'

import moment from 'moment'

import './CompanyList.scss'

export const CompanyList = ({ page = 1, name = '' }) => {
  const companies = useSelector((state) => state.companies)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const [modalShown, setModalShown] = useState(false)
  const [toRemoveId, setToRemoveId] = useState(-1)

  const [nameSearch, setNameSearch] = useState(name)

  const [csvDatas, setCsvDatas] = useState(null)

  const csvHeaders = [
    {
      label: t('ECPY Id'),
      key: 'ecpyId',
    },

    {
      label: t('Available tokens'),
      key: 'availableTokens',
    },

    {
      label: t('Subscription end date'),
      key: 'subscriptionEndDate',
    },
  ]

  useEffect(() => {
    moment.locale(i18n.language)
  }, [])

  useEffect(() => {
    dispatch(fetchCompanies(page, nameSearch))
  }, [page, name, nameSearch])

  useEffect(() => {
    async function fetchCompaniesForCsv() {
      try {
        const allCompanies = await companiesService.findAllNoPagination()

        let tempCompanies = []

        for (let company of allCompanies['hydra:member']) {
          const companyObj = {
            ecpyId: company.ecpyId,
            availableTokens:
              company.availableTokens === -1
                ? t('Illimited')
                : company.availableTokens,
            subscriptionEndDate: moment(company.subscriptionEndDate).format(
              'YYYY-MM-DD'
            ),
          }

          tempCompanies.push(companyObj)
        }

        setCsvDatas(tempCompanies)
      } catch (err) {}
    }

    fetchCompaniesForCsv()
  }, [])

  const navigateToPage = (page) => {
    history.push(`/admin/companies/page/${page}`)
  }

  const askToRemoveCompany = (id) => {
    setModalShown(true)
    setToRemoveId(id)
  }

  const removeCompany = async () => {
    let id = toRemoveId
    if (id !== -1) {
      try {
        const res = await companiesService.remove(id)

        if (res) {
          dispatch(
            alertActions.setAlert(
              'La société a bien été supprimée',
              alertConstants.SUCCESS
            )
          )
          dispatch(fetchCompanies(page))
        } else {
          dispatch(
            alertActions.setAlert(
              "Une erreur s'est produite",
              alertConstants.ERROR
            )
          )
        }
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR))
      }

      setToRemoveId(-1)
    }
  }

  const onModalOptionSelected = (option) => {
    if (option == 'no') {
      setModalShown(false)
    } else if (option == 'yes') {
      removeCompany()
      setModalShown(false)
    }
  }

  const handleNameSearchChange = (e) => {
    setNameSearch(e.target.value)
  }

  return (
    <>
      <h1 className='page-title'>
        <div className='float-right'>
          <Link to='/admin/companies/new'>
            <Button variant='primary'>{t('Add')}</Button>
          </Link>

          {csvDatas && (
            <CSVLink data={csvDatas} headers={csvHeaders}>
              <Button variant='primary' style={{ marginLeft: '10px' }}>
                {t('CSV export')}
              </Button>
            </CSVLink>
          )}
        </div>

        {t('Companies list')}
      </h1>

      <ValidationModal
        title={t('Remove company')}
        text={
          '<p>' +
          t(
            'You are about to remove a company. WARNING, this action cannot be undone.'
          ) +
          '</p><p>' +
          t('Do you confirm ?') +
          '</p>'
        }
        show={modalShown}
        onOptionSelected={onModalOptionSelected}
      />

      <div className='search'>
        <Form.Control
          type='text'
          placeholder={t('Search')}
          value={nameSearch}
          onChange={handleNameSearchChange}
        />
      </div>

      <Table bordered hover responsive>
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Subscription end date')}</th>
            <th>{t('Available tokens')}</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {companies.items.map((company) => (
            <tr key={company.id}>
              <td>{company.name}</td>
              <td>{moment(company.subscriptionEndDate).format('ll')}</td>
              <td>
                {company.availableTokens === -1
                  ? t('Unlimited')
                  : company.availableTokens}
              </td>
              <td>
                <Link to={'/admin/companies/' + company.id}>
                  <i className='companies-list__icon fas fa-eye'></i>
                </Link>

                <Link to={'/admin/companies/edit/' + company.id}>
                  <i className='companies-list__icon fas fa-edit'></i>
                </Link>

                <i
                  className='companies-list__icon fas fa-trash'
                  onClick={() => askToRemoveCompany(company.id)}
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {companies.page && (
        <JddwPagination
          navigateToPage={navigateToPage}
          page={companies.page}
          nbPages={companies.nbPages}
        />
      )}
    </>
  )
}
