import { contractFoldersService } from "../_services";

import { alertConstants } from "../_constants";
import { alertActions } from "./alert.actions";

export const fetchContractFoldersByBroker = (id, type) => async (dispatch) => {
  try {
    const contractFolders = await contractFoldersService.findByBroker(id, type);

    dispatch({
      type: "contractFolders/itemsLoaded",
      payload: contractFolders,
    });
  } catch (err) {
    dispatch({ type: "contractFolders/fetchError" });
    dispatch(alertActions.setAlert(err, alertConstants.ERROR));
  }
};

export const fetchContractFolder = (id) => async (dispatch) => {
  try {
    const contractFolder = await contractFoldersService.find(id);

    dispatch({
      type: "contractFolders/itemLoaded",
      payload: contractFolder,
    });
  } catch (err) {
    dispatch({ type: "contractFolders/fetchError" });
    dispatch(alertActions.setAlert(err, alertConstants.ERROR));
  }
};
export const emptyContractFolder = () => async (dispatch) => {
  dispatch({
    type: "contractFolders/empty",
  });
};
