import { contractsService } from "../_services";

import { alertConstants } from "../_constants";
import { alertActions } from "./alert.actions";

export const fetchContractsByBroker =
  (id, page = 1) =>
  async (dispatch) => {
    try {
      const contracts = await contractsService.findByBroker(id, page);

      dispatch({
        type: "contracts/itemsLoaded",
        payload: contracts,
      });
    } catch (err) {
      dispatch({ type: "contracts/fetchError" });
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }
  };
function groupBy(arr, n) {
  var group = [];
  for (var i = 0, end = arr.length / n; i < end; ++i)
    group.push(arr.slice(i * n, (i + 1) * n));
  return group;
}
const sortFields = (array, sortArray) => {
  return [...array].sort(
    (a, b) => sortArray.indexOf(a.name) - sortArray.indexOf(b.name)
  );
};
export const fetchContract = (id) => async (dispatch) => {
  try {
    let contract = await contractsService.find(id);

    dispatch({
      type: "contracts/itemLoaded",
      payload: contract,
    });
  } catch (err) {
    dispatch({ type: "contracts/fetchError" });
    dispatch(alertActions.setAlert(err, alertConstants.ERROR));
  }
};

export const emptyContract = () => async (dispatch) => {
  dispatch({
    type: "contracts/empty",
  });
};

export const switchLangAction = (lang) => async (dispatch) => {
  try {
    dispatch({
      type: "contracts/langLoaded",
      payload: lang,
    });
  } catch (err) {
    dispatch(alertActions.setAlert(err, alertConstants.ERROR));
  }
};
