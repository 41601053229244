import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import moment from "moment";

import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  Button,
  Modal,
  Form,
  Alert,
  Row,
  Col,
  Badge,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import {
  emptyContractFolder,
  fetchContractFolder,
  fetchContractFoldersByBroker,
} from "../_actions/contractFolders.actions";

import {
  alertActions,
  emptyContract,
  fetchContractTemplate,
  userActions,
} from "../_actions";
import { alertConstants } from "../_constants";

import {
  contractFoldersService,
  brokersService,
  contractsService,
  contractFieldValuesService,
} from "../_services";

import { encrypt, decrypt, getPublicKey } from "../_helpers";

import { PrivateKey } from "./_components/PrivateKey";

import { Link } from "react-router-dom";
import { brokers } from "../_reducers/brokers.reducer";

import { ValidationModal } from "../_components";

import { Loader } from "../_components";

import ReactPDF, { PDFDownloadLink, Document } from "@react-pdf/renderer";

import { createDocumentObj } from "./common/utils";

import { ContractDocument } from "./ContractDocument";

export const ContractFolderList = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const user = useSelector((state) => state.authentication.user);
  const contractFolders = useSelector((state) => state.contractFolders);

  const [isTitleModalShown, setTitleModalShown] = useState(false);
  const [isShareModalShown, setShareModalShown] = useState(false);
  const [isDuplicateModalShown, setDuplicateModalShown] = useState(false);
  const [isDeleteModalShown, setDeleteModalShown] = useState({
    status: null,
    show: false,
  });

  const [isLoaderShown, setLoaderShown] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    lang: "en",
  });

  const [shareData, setShareData] = useState({
    shareEmail: "",
  });

  const { name, lang } = formData;
  const { shareEmail } = shareData;

  const [currentSharedId, setCurrentSharedId] = useState(0);
  const [currentDeleteId, setCurrentDeleteId] = useState(0);
  const [currentDpulicateId, setcurrentDpulicateId] = useState(0);
  const privateKey = useSelector((state) => state.cryptKeys.privateKey);

  const [sharedFolders, setSharedFolders] = useState([]);
  const [duplicatedOriginalName, setDuplicatedOriginalName] = useState("");
  useEffect(() => {
    /*  dispatch(emptyContractFolder());
    dispatch(emptyContract()); */
    dispatch(emptyContractFolder());
    async function findSharedTo() {
      const sharedFolders = await contractFoldersService.findSharedTo(
        user.id,
        "transport"
      );
      setSharedFolders(sharedFolders);
    }
    dispatch(fetchContractFoldersByBroker(user.id, "transport"));
    findSharedTo();
    setLoaderShown(false);
  }, []);

  useEffect(() => {
    if (privateKey !== "" && contractFolders.items.length > 0) {
      const nameToTry = contractFolders.items.filter(
        (cf) => cf.sharedBrokers.length === 0
      )[0]?.name;

      if (!isPrivateKeyValid(nameToTry)) {
        dispatch(
          alertActions.setAlert(t("Incorrect key"), alertConstants.ERROR)
        );
      }
    }
  }, [privateKey]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const shareHandleChange = (e) => {
    setShareData({ ...shareData, [e.target.name]: e.target.value });
  };

  const handleTitleModalClose = () => {
    setTitleModalShown(false);
  };

  const handleDuplicateModalClose = () => {
    setDuplicatedOriginalName("");
    setDuplicateModalShown(false);
  };

  const openTitleModal = () => {
    setTitleModalShown(true);
  };

  const handleShareModalSubmit = async (e) => {
    try {
      setShareModalShown(false);
      setLoaderShown(true);

      const res = await brokersService.findByEmail(shareEmail);

      if (res["hydra:totalItems"] == 1) {
        const shareToUser = res["hydra:member"][0];

        const contractFolderToShare = await contractFoldersService.find(
          currentSharedId
        );

        if (contractFolderToShare.sharedBrokers.length === 0) {
          const decryptedFolderName = decryptInfo(contractFolderToShare.name);
          const folder = {
            id: currentSharedId,
            name: encrypt(decryptedFolderName, user.publicKey, user.publicKey),
          };
          await contractFoldersService.encryptFolder(folder);
          var encryptedFieldValues = [];
          contractFolderToShare.contracts.map((contract) => {
            contract.fieldValues.map((fieldValue) => {
              const decryptedFieldValue = decryptInfo(fieldValue.value);
              encryptedFieldValues.push({
                id: fieldValue.id,
                value: encrypt(
                  decryptedFieldValue,
                  user.publicKey,
                  user.publicKey
                ),
              });
            });
          });

          await contractFieldValuesService.editMultiple(encryptedFieldValues);
        }
        await contractFoldersService.shareContractFolder(
          currentSharedId,
          shareToUser.id
        );
        setShareData({ shareEmail: "" });
        dispatch(fetchContractFoldersByBroker(user.id, "transport"));

        setLoaderShown(false);
        setShareModalShown(false);
      } else {
        dispatch(
          alertActions.setAlert(
            t("This user doesn't exist"),
            alertConstants.ERROR
          )
        );
        setLoaderShown(false);
      }
    } catch (err) {
      dispatch(
        alertActions.setAlert("An error occured : " + err, alertConstants.ERROR)
      );
      setLoaderShown(false);
    }
  };

  const deleteModalOptionSelected = async (option) => {
    if (option == "no") {
      setDeleteModalShown({ status: null, show: false });
    } else if (option == "yes") {
      try {
        const res = await contractFoldersService.remove(currentDeleteId);

        dispatch(
          alertActions.setAlert(
            t("Contract has been removed"),
            alertConstants.SUCCESS
          )
        );

        dispatch(fetchContractFoldersByBroker(user.id, "transport"));
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }

      setDeleteModalShown({ status: null, show: false });
    }
  };
  const handledupicateContract = async (e) => {
    e.preventDefault();

    if (privateKey === "") {
      dispatch(
        alertActions.setAlert(
          t("You must set your private key to use this functionnality"),
          alertConstants.ERROR
        )
      );
    } else {
      if (!formData.name || formData.name === "") {
        dispatch(
          alertActions.setAlert(t("Name must be filled"), alertConstants.ERROR)
        );
      } else {
        if (formData.name === duplicatedOriginalName) {
          dispatch(
            alertActions.setAlert(
              t("Name must be different"),
              alertConstants.ERROR
            )
          );
        } else {
          const encryptedName = encrypt(
            `${formData.name}`,
            user.publicKey,
            privateKey
          );

          await contractFoldersService.duplicateContractFolder(
            currentDpulicateId,
            encryptedName
          );
          handleDuplicateModalClose();
          setFormData({ name: "" });
          dispatch(fetchContractFoldersByBroker(user.id, "transport"));
          dispatch(
            alertActions.setAlert(
              t("Contract has been duplicated"),
              alertConstants.SUCCESS
            )
          );
        }
      }
    }
  };

  const handleTitleModalSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (privateKey == "") {
      dispatch(
        alertActions.setAlert(
          t("You must set your private key to use this functionnality"),
          alertConstants.ERROR
        )
      );
    } else {
      if (!name || name == "") {
        dispatch(
          alertActions.setAlert(t("Name must be filled"), alertConstants.ERROR)
        );
        return;
      }

      const newContractFolder = {
        name: encrypt(name, user.publicKey, privateKey),
        owner: `/brokers/${user.id}`,
        status: "draft",
        lang: lang,
        folderType: "transport",
      };

      try {
        const res = await contractFoldersService.add(newContractFolder);
        setFormData({ name: "" });
        dispatch(
          alertActions.setAlert(
            t("Contract has been added"),
            alertConstants.SUCCESS
          )
        );
        dispatch(fetchContractFoldersByBroker(user.id, "transport"));
        //dispatch(fetchContractFolder(res.id));
        setTitleModalShown(false);
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    }
  };

  const decryptInfo = (info) => {
    if (!user.publicKey) {
      return info;
    }

    try {
      return decrypt(info, user.publicKey, privateKey);
    } catch (err) {
      return info;
    }
  };

  const decryptSharedInfo = (info, sharedFrom) => {
    if (!sharedFrom.publicKey) {
      return info;
    }

    try {
      return decrypt(info, sharedFrom.publicKey, sharedFrom.publicKey);
    } catch (err) {
      return info;
    }
  };

  const isPrivateKeyValid = (info) => {
    if (!user.publicKey) {
      return false;
    }

    try {
      decrypt(info, user.publicKey, privateKey);

      return true;
    } catch (err) {
      return false;
    }
  };

  return (
    <>
      <Loader isShown={isLoaderShown} />

      {user.firstConnection && (
        <Redirect to={{ pathname: "/first-connection" }} />
      )}
      <Modal show={isDuplicateModalShown} onHide={handleDuplicateModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("DUPLICATE CONTRACT")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t(
              "To duplicate a new contract, you can give it a name. It would be used as a reference, and never shown to your client"
            )}
          </p>

          <Form onSubmit={handledupicateContract}>
            <Form.Group controlId="contractName">
              <Form.Label>{t("Contract's name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Contract's name")}
                name="name"
                value={name}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleDuplicateModalClose}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={handledupicateContract}>
            {t("Duplicate")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isTitleModalShown} onHide={handleTitleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Add contract")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t(
              "To add a new contract, you must give it a name. It would be used as a reference, and never shown to your client"
            )}
          </p>

          <Form onSubmit={handleTitleModalSubmit}>
            <Form.Group controlId="contractName">
              <Form.Label>{t("Contract's name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Contract's name")}
                name="name"
                value={name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="contractLang">
              <Form.Label>{t("Contract's lang")}</Form.Label>
              <Form.Control
                as="select"
                name="lang"
                value={lang}
                onChange={handleChange}
              >
                <option value="en">EN</option>
                <option value="fr">FR</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleTitleModalClose}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={handleTitleModalSubmit}>
            {t("Add")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isShareModalShown} onHide={() => setShareModalShown(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Share contract")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t(
              "You want to share a contract with another user of the platform. Please fill his email"
            )}
          </p>

          <Form onSubmit={handleShareModalSubmit}>
            <Form.Group controlId="userEmail">
              <Form.Label>{t("User's email")}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t("User's email")}
                name="shareEmail"
                value={shareEmail}
                onChange={shareHandleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShareModalShown(false)}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={handleShareModalSubmit}>
            {t("Share")}
          </Button>
        </Modal.Footer>
      </Modal>

      {
        isDeleteModalShown.show && (
          /* (isDeleteModalShown.status === "draft" ? (*/
          <ValidationModal
            title={t("Delete contract")}
            text={
              "<p>" +
              t("WARNING ! This action cannot be undone.") +
              "</p>" +
              "<p>" +
              t("Do you wish to continue ?") +
              "</p>"
            }
            show={isDeleteModalShown.show}
            onOptionSelected={deleteModalOptionSelected}
          />
        )
        /* ) : (
          <Modal
            show={isDeleteModalShown.show}
            onHide={() => setDeleteModalShown({ status: null, show: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("WARNING")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>{t("You cannot delete a valid contract.")}</p>
            </Modal.Body>
          </Modal>
        )) */
      }

      <PrivateKey />

      {privateKey && sharedFolders && contractFolders && (
        <Row>
          <Col md={12}>
            <h1 className="page-title">
              {t("Your agreements")}
              {user?.publicKey &&
                ((contractFolders.items.some(
                  (cf) => cf.sharedBrokers.length === 0
                ) &&
                  isPrivateKeyValid(
                    contractFolders.items.filter(
                      (cf) => cf.sharedBrokers.length === 0
                    )[0]?.name
                  )) ||
                  !contractFolders.items.some(
                    (cf) => cf.sharedBrokers.length === 0
                  )) &&
                privateKey &&
                privateKey != "" &&
                (user?.company?.availableTokens === -1 ||
                  user?.company?.availableTokens > 0) && (
                  <Button
                    variant="success"
                    size="sm"
                    style={{
                      marginLeft: "15px",
                      padding: "2px 10px",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    onClick={openTitleModal}
                  >
                    + {t("New")}
                  </Button>
                )}
            </h1>

            <Table bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr
                  style={{
                    textAlign: "center",
                    backgroundColor: "#b3b2b2",
                    color: "#FFFFFF",
                  }}
                >
                  <th>{t("Name")}</th>

                  <th>{t("Status")}</th>

                  <th>{t("Lang")}</th>

                  <th>{t("Version")}</th>

                  <th>{t("Shared with")}</th>

                  <th>{t("Actions")}</th>
                </tr>
              </thead>

              <tbody>
                {user?.publicKey &&
                  privateKey &&
                  privateKey != "" &&
                  contractFolders?.items.map((contractFolder, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {contractFolder.sharedBrokers.length > 0 ||
                          contractFolder.createdFromShared
                            ? decryptSharedInfo(contractFolder.name, user)
                            : decryptInfo(contractFolder.name)}
                        </td>

                        <td>
                          {contractFolder.status == "draft" && (
                            <span style={{ color: "#e9725b" }}>
                              {t("Draft")}
                            </span>
                          )}
                          {contractFolder.status == "contract" && (
                            <span style={{ color: "#218838" }}>
                              {t("Contract")}
                            </span>
                          )}
                        </td>

                        <td>
                          {contractFolder.lang ? contractFolder.lang : "en"}
                        </td>

                        <td>{contractFolder.version}</td>

                        <td>
                          {contractFolder?.sharedBrokers.map(
                            (sharedContract) => (
                              <React.Fragment key={sharedContract.id}>
                                {sharedContract.email}

                                {(contractFolder.sharedBrokers.length > 0 ||
                                  contractFolder.createdFromShared ||
                                  isPrivateKeyValid(contractFolder.name)) && (
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    style={{ marginLeft: "10px" }}
                                    onClick={async () => {
                                      setLoaderShown(true);
                                      const decryptedFolderName =
                                        decryptSharedInfo(
                                          contractFolder?.name,
                                          user
                                        );
                                      const folder = {
                                        id: contractFolder?.id,
                                        name: encrypt(
                                          decryptedFolderName,
                                          contractFolder?.owner?.publicKey,
                                          privateKey
                                        ),
                                      };
                                      if (
                                        contractFolder?.sharedBrokers.length ===
                                        1
                                      ) {
                                        const decryptedFolderName =
                                          decryptSharedInfo(
                                            contractFolder.name,
                                            user
                                          );

                                        const folder = {
                                          id: contractFolder.id,
                                          name: encrypt(
                                            decryptedFolderName,
                                            user.publicKey,
                                            privateKey
                                          ),
                                        };
                                        await contractFoldersService.encryptFolder(
                                          folder
                                        );
                                        if (
                                          contractFolder.fieldValues.length >
                                            0 &&
                                          !contractFolder?.createdFromShared
                                        ) {
                                          var encryptedFieldValues = [];

                                          contractFolder.fieldValues.map(
                                            (fieldValue) => {
                                              const decryptedFieldValue =
                                                decryptSharedInfo(
                                                  fieldValue.value,
                                                  user
                                                );
                                              encryptedFieldValues.push({
                                                id: fieldValue?.id,
                                                value: encrypt(
                                                  decryptedFieldValue,
                                                  user.publicKey,
                                                  privateKey
                                                ),
                                              });
                                            }
                                          );
                                          await contractFieldValuesService.editMultiple(
                                            encryptedFieldValues
                                          );
                                        }
                                      }

                                      await contractFoldersService.refreshSharedInstancesContractFolder(
                                        contractFolder.id,
                                        sharedContract.id
                                      );
                                      setLoaderShown(false);
                                      dispatch(
                                        fetchContractFoldersByBroker(
                                          user.id,
                                          "transport"
                                        )
                                      );
                                    }}
                                    title={t("Delete")}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>
                                )}

                                <br />
                              </React.Fragment>
                            )
                          )}
                        </td>

                        <td>
                          {(contractFolder.sharedBrokers.length > 0 ||
                            contractFolder.createdFromShared ||
                            isPrivateKeyValid(contractFolder.name)) && (
                            <>
                              <Link
                                to={`/contracts/folders/${contractFolder.id}`}
                              >
                                <OverlayTrigger
                                  key="see"
                                  placement="top"
                                  overlay={
                                    <Tooltip id="see">
                                      {t("See / Modify")}
                                    </Tooltip>
                                  }
                                >
                                  <Button variant="outline-primary" size="sm">
                                    <i className="fa fa-list-alt"></i>
                                  </Button>
                                </OverlayTrigger>
                              </Link>

                              <OverlayTrigger
                                key="share"
                                placement="top"
                                overlay={
                                  <Tooltip id="share">{t("Share")}</Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setCurrentSharedId(contractFolder.id);
                                    setShareModalShown(true);
                                  }}
                                >
                                  <i className="fas fa-share-square"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="delete"
                                placement="top"
                                overlay={
                                  <Tooltip id="delete">{t("Delete")}</Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setCurrentDeleteId(contractFolder.id);
                                    setDeleteModalShown({
                                      status: contractFolder.status,
                                      show: true,
                                    });
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="dupliquer"
                                placement="top"
                                overlay={
                                  <Tooltip id="dupliquer">
                                    {t("Duplicate")}
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setcurrentDpulicateId(contractFolder.id);
                                    const originalName = decryptInfo(
                                      contractFolder.name
                                    );
                                    setDuplicatedOriginalName(originalName);
                                    setDuplicateModalShown(true);
                                  }}
                                >
                                  <i className="fa fa-clone"></i>
                                </Button>
                              </OverlayTrigger>
                            </>
                          )}

                          {/*{generatePdfFull(contractFolder)}*/}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>

            {sharedFolders?.length > 0 && (
              <>
                <h2 className="page-title">{t("Shared contracts")}</h2>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>{t("Name")}</th>

                      <th>{t("Shared by")}</th>

                      <th>{t("Status")}</th>

                      <th>{t("Lang")}</th>

                      <th>{t("Actions")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {user?.publicKey &&
                      privateKey &&
                      privateKey != "" &&
                      sharedFolders?.map((contractFolder, index) => (
                        <tr key={index}>
                          <td>
                            {decryptSharedInfo(
                              contractFolder.name,
                              contractFolder.owner
                            )}
                          </td>

                          <td>
                            {contractFolder.owner.name}{" "}
                            {contractFolder.owner.firstname}
                          </td>

                          <td>
                            {contractFolder.status == "draft" && (
                              <Badge pill variant="warning">
                                {t("Draft")}
                              </Badge>
                            )}
                            {contractFolder.status == "contract" && (
                              <Badge pill variant="success">
                                {t("Contract")}
                              </Badge>
                            )}
                          </td>

                          <td>
                            {contractFolder.lang ? contractFolder.lang : "en"}
                          </td>

                          <td>
                            <Link
                              to={`/contracts/folders/${contractFolder.id}`}
                            >
                              <Button variant="primary" size="sm">
                                <i className="fa fa-eye"></i>
                              </Button>
                            </Link>

                            <Button
                              variant="danger"
                              size="sm"
                              style={{ marginLeft: "10px" }}
                              onClick={async () => {
                                await contractFoldersService.refreshSharedInstancesContractFolder(
                                  contractFolder.id,
                                  user.id
                                );
                                dispatch(
                                  fetchContractFoldersByBroker(
                                    user.id,
                                    "transport"
                                  )
                                );
                              }}
                              title={t("Delete")}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
