import React from 'react'

export const AdminDashboard = () => {
  return (
    <>
      <h1 className='page-title'>Espace d'administration</h1>

      <p>
        Bienvenue dans votre espace d'administration. Bientôt ici plus
        d'informations et des widgets !
      </p>
    </>
  )
}
