import { indexOf } from "lodash";
import React, { useState } from "react";

import { Form, Button, Alert } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import {
  brokersService,
  contractHistoryService,
  companiesService,
} from "../_services";
import { contractsHistoriesSlice } from "../_reducers/contractsHistories.reducer";

export const CheckContract = () => {
  const { t } = useTranslation();

  const [agreementUniqueNumber, setAgreementUniqueNumber] = useState("");

  const [isValid, setIsValid] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isValidformat, setIsInvalidformat] = useState(false);
  const [broker, setbroker] = useState("");
  const [company, setCompany] = useState("");
  const checkAgreementNumber = async (e) => {
    e.preventDefault();

    let data = agreementUniqueNumber.trim().split(" ");

    if (data.length >= 2) {
      setIsInvalidformat(true);
    }
    if (agreementUniqueNumber != "") {
      try {
        const res = await contractHistoryService.getByAgreementNumber(
          agreementUniqueNumber
        );
        if (res["hydra:totalItems"] > 0) {
          let broker = res["hydra:member"][0]["broker"]["@id"].split("/");
          if (localStorage.getItem("user")) {
            const resbroker = await brokersService.find(broker[2]);
            setbroker(resbroker["name"]);
            setCompany(resbroker["company"]["name"]);
          }

          setIsValid(true);
        } else {
          setIsInvalid(true);
        }
      } catch (err) {}
    }
  };

  return (
    <div
      style={{
        marginTop: "35px",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        borderRadius: "25px",
        padding: "25px",
      }}
    >
      <h1 className="page-title">{t("Check a contract")}</h1>

      <p>
        {t(
          "Please enter the Agreement's unique number in order to verify if it was generated by our platform."
        )}
      </p>

      <p style={{ fontWeight: "bold" }}>
        {t("Warning") +
          " : " +
          t("don't enter version information (v1 / v2 / ...)")}
      </p>

      <Form onSubmit={checkAgreementNumber}>
        <Form.Control
          controlId="agreementUniqueNumber"
          type="text"
          placeholder={"ex : c460g7ac-1av8-4f95-4953-fv8d9ve8bf40"}
          required
          name="agreementUniqueNumber"
          value={agreementUniqueNumber}
          onChange={(e) => {
            setIsValid(false);
            setIsInvalid(false);
            setAgreementUniqueNumber(e.target.value);
          }}
        />

        <Button type="submit" variant="success" style={{ marginTop: "20px" }}>
          {t("Check")}
        </Button>
      </Form>

      {isValid && broker && company && (
        <Alert variant="success">
          <p>
            {t(
              "This agreement has been generated by ECPY transport and charter agreements platform. It has been created by "
            ) +
              " " +
              broker +
              " (" +
              company +
              ")"}
          </p>
        </Alert>
      )}
      {isValid && !broker && !company && (
        <Alert variant="success">
          <p>
            {t(
              "This agreement has been generated by ECPY transport and charter agreements platform."
            )}
          </p>
        </Alert>
      )}

      {isInvalid && (
        <Alert variant="danger">
          <p>{t("This agreement has not been generated by the platform.")}</p>
        </Alert>
      )}

      {isValidformat && (
        <Alert variant="danger">
          <p>
            {t(
              "this argument should not contain any information about the version (v1 / v2 / ...)."
            )}
          </p>
        </Alert>
      )}
    </div>
  );
};
