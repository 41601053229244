import React, { useEffect, useState } from 'react'

import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { useSelector, useDispatch } from 'react-redux'

import { fetchCompanies, emptyCompanies } from '../../_actions/company.actions'

import './CompanyNameSearchField.scss'

import Select from 'react-select'

const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: 'none',
    minWidth: '242px',
    zIndex: 9999,
    border: '1px solid lightgray', // default border color
    '&:hover': { borderColor: 'gray' }, // border style on hover
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,

    backgroundColor:
      (isSelected && '#e9725b') || (isFocused && '#e9725b') || 'transparent',

    '&:hover': {
      backgroundColor: '#e9725b',
      color: isSelected || isFocused ? '#FFFFFF' : '#000000',
    },
  }),
}

export const CompanyNameSearchField = ({
  onClickHandle,
  name = '',
  onChangeHandle = null,
}) => {
  const companies = useSelector((state) => state.companies)
  const dispatch = useDispatch()

  const [companiesOptions, setCompaniesOptions] = useState([])

  const [nameSearch, setNameSearch] = useState(name)

  useEffect(() => {
    setNameSearch(name)
  }, [name])

  useEffect(() => {
    if (nameSearch != '') {
      dispatch(fetchCompanies(1, nameSearch))
    } else {
      dispatch(emptyCompanies())
    }
  }, [nameSearch])

  useEffect(() => {
    if (companies) {
      let companiesOptionsTemp = []

      for (let company of companies.items) {
        companiesOptionsTemp.push({
          value: company.id,
          label: company.name,
        })
      }

      setCompaniesOptions(companiesOptionsTemp)
    }
  }, [companies])

  /*
  const handleNameSearchChange = (e) => {
    setNameSearch(e.target.value)
    onChangeHandle(e.target.value)

    const company = companies.items.find((c) => c.name === e.target.value)
    if (company) {
      onClickHandle(company)
    }
  }
  */

  const handleInputValueChange = (value) => {
    setNameSearch(value)
    if (value !== '') {
      onChangeHandle(value)
    }
  }

  const handleChange = (companyOption) => {
    //setNameSearch(companyOption.label)
    //onChangeHandle(companyOption.label)

    const company = companies.items.find((c) => c.id === companyOption.value)

    if (company) {
      onClickHandle(company)
    }
  }

  /*
  const clickHandle = (company) => {
    setNameSearch(company.name)
    onClickHandle(company)
  }
  */

  return (
    <>
      <Select
        name='react-select-companies'
        options={companiesOptions}
        inputValue={nameSearch}
        onInputChange={handleInputValueChange}
        onChange={handleChange}
        isClearable={true}
        isSearchable={true}
        styles={customStyles}
        classNamePrefix='react-select'
      />

      {/*<div className='search'>
        <Form.Control
          type='text'
          placeholder='Rechercher'
          value={nameSearch}
          onChange={handleNameSearchChange}
        />
      </div>

      <Table striped bordered hover responsive className='company-name-search'>
        <tbody>
          {companies.items.map((companyItem) => (
            <tr
              key={companyItem.id}
              className={
                companyItem.name == nameSearch
                  ? 'company-name-search__row company-name-search__row--active'
                  : 'company-name-search__row'
              }
              onClick={() => clickHandle(companyItem)}
            >
              <td className='company-name-search__cell'>{companyItem.name}</td>
            </tr>
          ))}

          {nameSearch != '' && companies.items.length == 0 && (
            <tr>
              <td>Aucun résultat</td>
            </tr>
          )}
        </tbody>
          </Table>*/}
    </>
  )
}
