import axios from "axios";

import { ApiService } from "./api";
import {
  contractsTemplateService,
  contractsService,
  contractFieldService,
} from "./";

import { authHeader } from "../_helpers";

const apiService = new ApiService();
apiService.setApiUrl("/contract_folders");

export const contractFoldersService = {
  findAll,
  find,
  add,
  update,
  remove,
  findByBroker,
  findSharedTo,
  addContractToFolder,
  duplicateContractFolder,
  shareContractFolder,
  refreshSharedInstancesContractFolder,
  findByBrokerViaApiPlatform,
  renameContractFolder,
  setDraftOrContract,
  encryptFolder,
};

async function add(item) {
  return apiService.add(item);
}

async function findAll(page = 1) {
  return apiService.findAll(page);
}

async function find(id) {
  return apiService.find(id);
}
async function refreshSharedInstancesContractFolder(id, broker) {
  try {
    const res = await axios.put(
      `${apiService.apiUrl}/refresh/${id}/${broker}`,
      null,
      authHeader()
    );
    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}
async function update(item) {
  return apiService.update(item);
}

async function remove(id) {
  //return apiService.remove(id);
  try {
    const res = await axios.delete(
      `${apiService.getApiUrl()}/delete/${id}`,
      authHeader()
    );

    return res.data;
  } catch (err) {
    throw this.manageErrors(err);
  }
}
async function duplicateContractFolder(folderId, name) {
  try {
    const body = JSON.stringify({ name });

    const res = await axios.post(
      `${apiService.apiUrl}/duplicate/${folderId}`,
      body,
      authHeader()
    );
    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}
async function shareContractFolder(folderId, shareTo) {
  try {
    const res = await axios.post(
      `${apiService.apiUrl}/share/${folderId}/${shareTo}`,
      null,
      authHeader()
    );
    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}
async function findByBroker(brokerId, type) {
  try {
    const res = await axios.get(
      `${apiService.apiUrl}/get/${type}/${brokerId}`,
      authHeader()
    );
    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

async function findByBrokerViaApiPlatform(id, page = 1, type) {
  let params = authHeader();
  params.params = { owner: `/brokers/${id}`, page: page, folderType: type };

  try {
    const res = await axios.get(`${apiService.apiUrl}`, params);

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw apiService.manageErrors(err);
  }
}

async function findSharedTo(brokerId, type) {
  try {
    const res = await axios.get(
      `${apiService.apiUrl}/get/shared/${type}/${brokerId}`,
      authHeader()
    );
    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

const getAddendumId = (contractFolder) => {
  const existingAddendums = contractFolder?.contracts?.filter(
    (contract) =>
      contract.template.codeName === "addendum" ||
      contract.template.codeName === "AddendumCharter"
  );
  const addendumId = existingAddendums.length + 1;

  return addendumId;
};
async function addContractToFolder(contractFolder, templateObj) {
  let templateName = templateObj.name;
  if (
    templateObj.codeName === "addendum" ||
    templateObj.codeName === "AddendumCharter"
  ) {
    const addendumId = getAddendumId(contractFolder);
    templateName = `Addendum #${addendumId}`;
  }

  const tempTemplate = {
    name: templateName,
    codeName: templateObj.codeName,
    parent: `/contract_templates/${templateObj.id}`,
    visible: false,
  };

  const newTemplate = await contractsTemplateService.add(tempTemplate);

  const newContract = {
    contractFolder: `/contract_folders/${contractFolder.id}`,
    template: `/contract_templates/${newTemplate.id}`,
  };

  await contractsService.add(newContract);
}

async function renameContractFolder(id, name) {
  try {
    const data = JSON.stringify(name);

    const res = await axios.put(
      `${apiService.apiUrl}/rename/${id}`,
      data,
      authHeader()
    );
    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

async function setDraftOrContract(id) {
  try {
    const res = await axios.put(
      `${apiService.apiUrl}/validate/${id}`,
      null,
      authHeader()
    );
    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

async function encryptFolder(data) {
  try {
    const body = JSON.stringify(data);

    const res = await axios.put(
      `${apiService.apiUrl}/encrypt/${data.id}`,
      body,
      authHeader()
    );
    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}
