import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { brokersService, companiesService } from "../../_services";
import { alertActions } from "../../_actions";
import { alertConstants } from "../../_constants";

import { fetchCompanies } from "../../_actions/company.actions";

import { history } from "../../_helpers";

import { Link } from "react-router-dom";

import { CompanyNameSearchField } from "../companies/CompanyNameSearchField";

import { useTranslation } from "react-i18next";

export const BrokersAddEdit = ({ id }) => {
  const companies = useSelector((state) => state.companies.items);

  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const isAddMode = !id;

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    firstname: "",
    company: {},
    welcomed: false,
  });

  const { email, name, firstname, company, welcomed } = formData;

  const [searchedName, setSearchedName] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    (async () => {
      if (!isAddMode) {
        // Edit mode => need to fetch user
        try {
          const broker = await brokersService.find(id);

          setFormData({
            email: broker.email,
            name: broker.name,
            firstname: broker.firstname,
            company: broker.company,
          });

          setSearchedName(broker.company.name);
        } catch (err) {
          dispatch(alertActions.setAlert(err, alertConstants.ERROR));
        }
      }
    })();
  }, [id]);

  const handleChange = (e) => {
    if (e.target.name == "company") {
      setFormData({
        ...formData,
        company: { ...company, id: e.target.value },
      });
    } else if (e.target.name == "welcomed") {
      setFormData({
        ...formData,
        welcomed: e.target.checked,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let updatedCompany = {};

    if (!selectedCompany && !company) {
      dispatch(
        alertActions.setAlert(t("Company is mandatory"), alertConstants.ERROR)
      );

      return;
    } else if (company && !selectedCompany) {
      updatedCompany = company;
    } else {
      updatedCompany = selectedCompany;
    }

    if (isAddMode) {
      try {
        const company = updatedCompany.id;
        await brokersService.add(email, name, firstname, company, welcomed);

        dispatch(
          alertActions.setAlert(
            t("Broker has been added"),
            alertConstants.SUCCESS
          )
        );

        history.push("/admin/brokers");
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    } else {
      // Edit
      try {
        const companyIRI = `/companies/${updatedCompany.id}`;

        const updatedBroker = await brokersService.update({
          id,
          email,
          name,
          firstname,
          companyIRI,
        });

        if (formData.welcomed === true) {
          await brokersService.sendWelcome(id);
        }

        dispatch(
          alertActions.setAlert(
            t("Broker has been updated"),
            alertConstants.SUCCESS
          )
        );

        history.push("/admin/brokers/" + updatedBroker.id);
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    }
  };

  const onCompanyClicked = (clickedCompany) => {
    /*setFormData({
      ...formData,
      company: clickedCompany,
    })*/

    setSelectedCompany(clickedCompany);
    setSearchedName(clickedCompany.name);
  };

  return (
    <>
      <h1 className="page-title">
        {isAddMode ? t("Add broker") : t("Update broker")}
      </h1>

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="brokerEmail">
          <Form.Label>{t("Email")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("Email")}
            required
            name="email"
            value={email}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="brokerName">
          <Form.Label>{t("Name")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Name")}
            required
            name="name"
            value={name}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="brokerFirstname">
          <Form.Label>{t("Firstname")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Firstname")}
            required
            name="firstname"
            value={firstname}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="brokerCompany">
          <Form.Label>{t("Company")}</Form.Label>
          <CompanyNameSearchField
            name={company.name}
            onClickHandle={onCompanyClicked}
            onChangeHandle={setSearchedName}
          />
        </Form.Group>

        <Form.Group controlId="brokerWelcomed">
          <Form.Check
            type="checkbox"
            label={t("Send welcome email")}
            name="welcomed"
            value={welcomed}
            checked={welcomed}
            onChange={handleChange}
          />
        </Form.Group>

        <Button type="submit" variant="primary">
          {isAddMode ? t("Add") : t("Update")}
        </Button>

        <Button onClick={history.goBack} variant="outline-primary">
          {t("Go back")}
        </Button>
      </Form>
    </>
  );
};
