import React from "react";
import { Button, Container } from "react-bootstrap";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "", info: "", stack: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, info: errorInfo, stack: error.stack });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <h4>Something went wrong!</h4>
          <Button role="button" onClick={() => window.location.reload()}>
            Try again
          </Button>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
