import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    paddingRight: "20px",
    paddingLeft: "50px",
    paddingTop: "10px",
    fontFamily: "Helvetica",
  },

  textContent: {
    fontSize: 9,
    lineHeight: 1.2
   // marginTop: 5,
   // marginBottom: 5,
  },

  checkboxWrapper: {
    position: "relative",
    paddingLeft: "20pt",
    paddingRight: "5pt",
  },

  checkbox: {
    border: "2pt solid #000000",
    width: "10pt",
    height: "10pt",
    position: "absolute",
    top: "2pt",
  },

  checkboxChecked: {
    border: "2pt solid #000000",
    width: "10pt",
    height: "10pt",
    backgroundColor: "#555555",
    position: "absolute",
    top: "2pt",
  },
});

export default styles;
