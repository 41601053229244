import { userConstants } from "../_constants";
import { userService, brokersService } from "../_services";
import { alertActions } from "./alert.actions";
import { alertConstants } from "../_constants";
import { history } from "../_helpers";

import moment from "moment";

import i18n from "../i18n";

export const userActions = {
  login,
  logout,
  refreshToken,
  fetchBrokerUser,
  updateUser,
  resetPassword,
};

function login(email, password) {
  return async (dispatch) => {
    dispatch(request({ email }));

    try {
      const user = await userService.login(email, password);
      if (user && checkUserValid(user, dispatch)) {
        dispatch(success(user));
        dispatch(
          alertActions.setAlert(
            i18n.t("You're now logged in"),
            alertConstants.SUCCESS
          )
        );

        const isAdmin = (el) => el === "ROLE_ADMIN";

        if (user.roles && user.roles.findIndex(isAdmin) !== -1) {
          history.push("/admin");
        } else {
          dispatch(fetchBrokerUser(user));
          history.push("/");
        }
      }
    } catch (err) {
      dispatch(logout());
      dispatch(failure(err));
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }
  };

  function request(user) {
    return {
      type: userConstants.LOGIN_REQUEST,
      user,
    };
  }

  function success(user) {
    return {
      type: userConstants.LOGIN_SUCCESS,
      user,
    };
  }

  function failure(error) {
    return {
      type: userConstants.LOGIN_FAILURE,
      error,
    };
  }
}

function logout() {
  return (dispatch) => {
    userService.logout();
    dispatch({
      type: userConstants.LOGOUT,
    });
    dispatch(
      alertActions.setAlert(
        i18n.t("You're now logged out"),
        alertConstants.SUCCESS
      )
    );
    history.push("/");
  };
}

function refreshToken(token) {
  return async (dispatch) => {
    try {
      const user = await userService.refreshToken(token);

      if (user && checkUserValid(user, dispatch)) {
        dispatch({
          type: userConstants.AUTH_SUCCESS,
          user,
        });

        const isAdmin = (el) => el === "ROLE_ADMIN";

        if (user.roles && user.roles.findIndex(isAdmin) === -1) {
          dispatch(fetchBrokerUser(user));
        }
      }
    } catch (err) {
      dispatch(logout());
      dispatch({
        type: userConstants.AUTH_ERROR,
      });
    }
  };
}

function fetchBrokerUser(user) {
  return async (dispatch) => {
    try {
      const broker = await brokersService.find(user.id);
      dispatch({
        type: userConstants.AUTH_SUCCESS,
        user: broker,
      });
    } catch (err) {
      dispatch({
        type: userConstants.AUTH_ERROR,
      });
    }
  };
}

function updateUser(user) {
  return async (dispatch) => {
    try {
      const broker = await brokersService.find(user.id);
      dispatch({
        type: userConstants.USER_UPDATE,
        user: broker,
      });
    } catch (err) {
      dispatch({
        type: userConstants.AUTH_ERROR,
      });
    }
  };
}

function checkUserValid(user, dispatch) {
  if (!user.active) {
    dispatch({
      type: userConstants.LOGIN_FAILURE,
      payload: user,
    });

    dispatch(
      alertActions.setAlert(
        i18n.t("Your account has been deactivated"),
        alertConstants.ERROR
      )
    );

    return false;
  } else if (
    user.company &&
    user.company.subscriptionEndDate &&
    moment(user.company.subscriptionEndDate.date).isBefore(moment())
  ) {
    dispatch({
      type: userConstants.LOGIN_FAILURE,
      payload: user,
    });

    dispatch(
      alertActions.setAlert(
        i18n.t("Your company has no more subscription"),
        alertConstants.ERROR
      )
    );

    return false;
  }

  return true;
}

function resetPassword(email, setIsLoading) {
  return async (dispatch) => {
    setIsLoading(true);
    try {
      const reset = await userService.resetPassword(email);
      dispatch(alertActions.setAlert(i18n.t(reset), alertConstants.SUCCESS));
      history.push("/");
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }
    setIsLoading(false);
  };
}
