import axios from 'axios'

import { authHeader } from '../_helpers'

const API_URL = `${process.env.REACT_APP_API_ENTRYPOINT}/media_objects`

export const mediaobjectService = {
  add,
}

function manageErrors(err) {
  if (err.response?.data) {
    const type = err.response.data['@type']
    if (type && type == 'ConstraintViolationList') {
      return err.response.data['hydra:description']
    } else {
      return err.response.data.message
    }
  }

  return err
}

async function add(file) {
  try {
    const formData = new FormData()
    formData.append('file', file)

    let params = authHeader()
    params.headers['Content-Type'] = 'multipart/form-data'

    const res = await axios.post(`${API_URL}`, formData, params)
    if (res.status == 201) {
      return res.data
    } else {
      throw res.data['hydra:description']
    }
  } catch (err) {
    throw manageErrors(err)
  }
}
