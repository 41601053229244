export const fetchPrivateKey = () => (dispatch) => {
  if (sessionStorage.privateKey) {
    dispatch({
      type: 'cryptKeys/privateLoaded',
      payload: sessionStorage.privateKey,
    })
  } else {
    dispatch({
      type: 'cryptKeys/privateCleared',
      payload: '',
    })
  }
}

export const storePrivateKey = (key) => (dispatch) => {
  sessionStorage.privateKey = key
  dispatch({
    type: 'cryptKeys/privateLoaded',
    payload: key,
  })
}
