import axios from "axios";

import { ApiService } from "./api";

import { authHeader } from "../_helpers";

const apiService = new ApiService();
apiService.setApiUrl("/contract_field_values");

export const contractFieldValuesService = {
  add,
  addMultiple,
  update,
  updateMultiple,
  getAxiosPostReq,
  removeMultiple,
  editMultiple,
};

async function add(item) {
  return apiService.add(item);
}

async function update(item) {
  return apiService.update(item);
}

async function addMultiple(items) {
  try {
    const response = await axios.post(
      `${apiService.getApiUrl()}/collection`,
      items,
      authHeader()
    );

    return response;
  } catch (err) {
    throw apiService.manageErrors(err);
  }
}

async function editMultiple(items) {
  try {
    const response = await axios.put(
      `${apiService.getApiUrl()}/edit/multiple`,
      items,
      authHeader()
    );

    return response;
  } catch (err) {
    throw apiService.manageErrors(err);
  }
}

async function updateMultiple(items) {
  let reqs = [];
  for (let item of items) {
    const body = JSON.stringify(item);
    const req = await axios.put(
      `${apiService.getApiUrl()}/${item.id}`,
      body,
      authHeader()
    );

    reqs.push(req);
  }

  try {
    return await Promise.all(reqs);
  } catch (err) {
    throw apiService.manageErrors(err);
  }
}

async function getAxiosPostReq(item) {
  const body = JSON.stringify(item);
  return await axios.post(apiService.getApiUrl(), body, authHeader());
}

async function removeMultiple(items) {
  let reqs = [];

  for (let item of items) {
    const id = item.id;
    reqs.push(axios.delete(`${apiService.getApiUrl()}/${id}`, authHeader()));
  }

  try {
    const res = await axios.all(reqs);

    return res;
  } catch (err) {
    throw apiService.manageErrors(err);
  }
}
