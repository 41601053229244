import axios from 'axios'

import { ApiService } from './api'

import { authHeader } from '../_helpers'

const apiService = new ApiService()
apiService.setApiUrl('/contract_histories')

export const contractHistoryService = {
  findAll,
  findAllNoPagination,
  add,
  update,
  getByAgreementNumber,
}

async function findAll(page = 1) {
  return apiService.findAll(page)
}

async function findAllNoPagination() {
  let parameters = `pagination=false`

  try {
    const res = await axios.get(
      `${apiService.getApiUrl()}?${parameters}`,
      authHeader()
    )

    if (res.status === 200) {
      return res.data
    } else {
      throw res.data['hydra:description']
    }
  } catch (err) {
    throw apiService.manageErrors(err)
  }
}

async function add(item) {
  return apiService.add(item)
}

async function update(item) {
  return apiService.update(item)
}

async function getByAgreementNumber(agreementUniqueNumber) {
  try {
    const res = await axios.get(
      `${apiService.apiUrl}?agreementUniqueNumber=${agreementUniqueNumber}`,
      authHeader()
    )

    if (res.status === 200) {
      return res.data
    } else {
      throw res.data['hydra:description']
    }
  } catch (err) {
    throw apiService.manageErrors(err)
  }
}
