import axios from "axios";
import jwt_decode from "jwt-decode";

import { authHeader } from "../_helpers";
import moment from "moment";

const API_URL = process.env.REACT_APP_API_ENTRYPOINT;

export const userService = {
  login,
  logout,
  refreshToken,
  update,
  resetPassword,
};

async function login(email, password) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(
      `${API_URL}/authentication_token`,
      body,
      config
    );

    if (res.status === 200) {
      let user = {};
      user.token = res.data.token;
      user.refresh_token = res.data.refresh_token;

      var decoded = jwt_decode(user.token);

      user.id = decoded.id;
      user.email = decoded.email;
      user.roles = decoded.roles;
      user.active = decoded.active;

      if (decoded.company) {
        user.company = decoded.company;
      }

      if (decoded.sharedFolders) {
        user.sharedFolders = decoded.sharedFolders;
      }

      localStorage.setItem("user", JSON.stringify(user));
      const expiresAt = moment().add(4, "h");
      localStorage.setItem("expiresAt", expiresAt);

      return user;
    } else {
      logout();
      throw res.data.message;
    }
  } catch (err) {
    logout();
    throw err.response.data.message;
  }
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("expiresAt");
}

async function refreshToken(refreshToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ refresh_token: refreshToken });

  try {
    const res = await axios.post(`${API_URL}/refresh_token`, body, config);

    if (res.status === 200) {
      let user = {};
      user.token = res.data.token;
      user.refresh_token = res.data.refresh_token;

      var decoded = jwt_decode(user.token);

      user.id = decoded.id;
      user.email = decoded.email;
      user.roles = decoded.roles;
      user.active = decoded.active;

      if (decoded.company) {
        user.company = decoded.company;
      }

      if (decoded.sharedFolders) {
        user.sharedFolders = decoded.sharedFolders;
      }

      localStorage.setItem("user", JSON.stringify(user));

      return user;
    } else {
      logout();
      throw res.data.message;
    }
  } catch (err) {
    logout();
    throw err.response.data.message;
  }
}

async function update(user) {
  const body = JSON.stringify(user);

  try {
    const res = await axios.put(
      `${API_URL}/users/${user.id}`,
      body,
      authHeader()
    );

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw err.response.data.message;
  }
}

async function resetPassword(email) {
  try {
    const body = JSON.stringify({ email });
    const res = await axios.patch(
      `${API_URL}/users/reset_password`,
      body,
      authHeader()
    );
    return res.data;
  } catch (err) {
    throw err.response.data.detail;
  }
}
