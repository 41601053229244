import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import { Button, Row, Col } from "react-bootstrap";

import { fetchLibraryDocs, alertActions } from "../../_actions";
import { alertConstants } from "../../_constants";
import { LibraryAdd } from "./LibraryAdd";

import { JddwPagination } from "../../_components";

import { history, authHeader } from "../../_helpers";

import { ValidationModal } from "../../_components";

import "./Library.scss";
import { libraryDocsService } from "../../_services";

import { Loader } from "../../_components";

import axios from "axios";

export const LibraryIndex = ({ page = 1 }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const libraryDocs = useSelector((state) => state.libraryDocs);

  const [modalShown, setModalShown] = useState(false);
  const [toRemoveId, setToRemoveId] = useState(-1);

  const [isLoaderShown, setLoaderShown] = useState(false);

  useEffect(() => {
    dispatch(fetchLibraryDocs(page));
  }, [page]);

  const onDocumentAdded = () => {
    dispatch(fetchLibraryDocs(page));
  };

  const navigateToPage = (page) => {
    history.push(`/admin/library/${page}`);
  };

  const askToRemoveDoc = (id) => {
    setToRemoveId(id);
    setModalShown(true);
  };

  const onModalOptionSelected = (option) => {
    if (option == "no") {
      setModalShown(false);
      setToRemoveId(-1);
    } else {
      removeDoc();
      setModalShown(false);
    }
  };

  const removeDoc = async () => {
    let id = toRemoveId;

    if (id !== -1) {
      try {
        const res = await libraryDocsService.remove(id);

        if (res) {
          dispatch(
            alertActions.setAlert(
              "La notice a bien été supprimée",
              alertConstants.SUCCESS
            )
          );
          dispatch(fetchLibraryDocs(page));
        } else {
          dispatch(
            alertActions.setAlert(
              "Une erreur s'est produite",
              alertConstants.ERROR
            )
          );
        }
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }

      setToRemoveId(-1);
    }
  };

  const downloadFile = async (libraryDoc) => {
    setLoaderShown(true);

    let params = authHeader();
    params.responseType = "blob";

    const file = await axios.get(
      `${process.env.REACT_APP_API_ENTRYPOINT}/download_file/${libraryDoc.id}`,
      params
    );

    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(
      new Blob([file.data], { type: "application/octet-stream" })
    );

    const fileDatas = libraryDoc.document.contentUrl.split("/");
    const filename = fileDatas[2];

    link.download = filename;

    document.body.appendChild(link);

    setLoaderShown(false);

    link.click();

    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  };

  return (
    <>
      <Loader isShown={isLoaderShown} />

      <ValidationModal
        title="Suppression d'une notice"
        text='<p>Vous êtes sur le point de supprimer une notice. Attention, cette action est irréversible !</p> <p style="color: #192d80;">Etes vous sûr de vouloir continuer ?</p>'
        show={modalShown}
        onOptionSelected={onModalOptionSelected}
      />

      <h1 className="page-title">Notices</h1>

      <h2 className="page-subtitle">Ajouter une notice</h2>

      <div className="library-add">
        <LibraryAdd onAdded={onDocumentAdded} />
      </div>

      <div className="libraryDocs-list" style={{ marginTop: "15px" }}>
        <Row className="justify-content-md-center">
          <Col md={6}>
            {libraryDocs.items.map((doc) => (
              <div key={doc.id} className="libraryDocs-list__item">
                <h4 className="libraryDocs__title">{doc.title}</h4>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile(doc);
                  }}
                >
                  <i className="fas fa-file-download libraryDocs__icon"></i>
                </a>

                <i
                  className="fas fa-trash libraryDocs__icon libraryDocs__icon-trash"
                  onClick={(e) => askToRemoveDoc(doc.id)}
                ></i>
              </div>
            ))}
          </Col>
        </Row>
      </div>

      {libraryDocs.page && (
        <JddwPagination
          navigateToPage={navigateToPage}
          page={libraryDocs.page}
          nbPages={libraryDocs.nbPages}
        />
      )}
    </>
  );
};
