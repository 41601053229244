import React from 'react'

import { useTranslation } from 'react-i18next'

export const CompanyInformations = ({ company }) => {
  const { t } = useTranslation()

  return (
    <div className='company__informations'>
      <h3 className='page-subtitle'>{t('Informations')}</h3>

      <p>
        {t('Address')} 1 : {company.address1}
      </p>
      <p>
        {t('Address')} 2 : {company.address2}
      </p>
      <p>
        {t('Zipcode')} : {company.zipCode}
      </p>
      <p>
        {t('City')} : {company.city}
      </p>
      <p>
        {t('Country')} : {company.country}
      </p>
      <p>
        {t('MYBAid')} : {company.MYBAid}
      </p>
      <p>
        {t('Registration Number')} : {company.registrationNumber}
      </p>
      <p>
        {t('Registration Country')} : {company.registrationCountry}
      </p>
      <p>
        {t('VAT Number')}: {company.VATnumber}
      </p>
      <p>
        {t('Insurance')} : {company.insurance}
      </p>
      <p>
        {t('Financial Guarantee')} : {company.financialGuarantee}
      </p>
      <p>
        {t('ECPY Id')} : {company.ecpyId}
      </p>
    </div>
  )
}
