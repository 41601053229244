import React from 'react'

import './Loader.scss'

import { useTranslation } from 'react-i18next'

export const Loader = ({ isShown }) => {
  const { t } = useTranslation()

  return (
    <div
      className='loader-wrapper'
      style={{ display: isShown ? 'flex' : 'none' }}
    >
      <div className='loader' id='loader-1'></div>
      <div className='loader__text'>{t('Loading...')}</div>
    </div>
  )
}
