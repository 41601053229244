import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: "20px 50px",
    fontFamily: "Open Sans",
  },

  textContent: {
    fontSize: 10,
    marginTop: 5,
    marginBottom: 5,
  },
});

const jsonView = {
  type: "page",
  items: [
    {
      type: "view",
      styles: {
        paddingLeft: 25,
        paddingTop: 8,
      },
      items: [
        {
          type: "text",
          styles: {
            fontSize: 16,
            marginTop: 15,
            textAlign: "center",
            fontFamily: "Helvetica-Bold",
          },
          content: "APPENDIX I - ITINERARY - PASSENGERS - AMENDMENTS",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,

            marginTop: 35,
            fontFamily: "Helvetica-Bold",
          },
          content: "ITINERARY",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
          },
          content: "CRUISING AREA : __itineraryCruisingArea__",
        },
      ],
    },

    {
      type: "view",
      styles: {
        margin: 10,
        marginLeft: 20,
        padding: 10,
        border: "1pt solid #000000",
      },
      items: [
        {
          type: "view",
          styles: {
            flexDirection: "row",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "40%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                    textAlign: "left",
                  },
                  content: "PORT",
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "30%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    textAlign: "left",
                  },
                  content: "Date - Time of Arrival",
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "30%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    textAlign: "left",
                  },
                  content: "Date - Time of Departure",
                },
              ],
            },
          ],
        },

        {
          type: "view",
          styles: {
            flexDirection: "row",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "40%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                    marginBottom: 0,
                    fontStyle: "italic",
                  },
                  content: "Port of embarkation",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                    textAlign: "left",
                    marginBottom: 15,
                  },
                  content: "__itineraryEmbarkationPort__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "30%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    textAlign: "left",
                  },
                  content: "",
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "30%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    marginBottom: 0,
                  },
                  content: " ",
                },
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    textAlign: "left",
                    marginBottom: 15,
                  },
                  content:
                    "__cruiseStartDateItinerary__ __cruiseStartTimeItinerary__",
                },
              ],
            },
          ],
        },

        {
          type: "view",
          repeated: true,
          findRepeated: "itineraryPorts_name",
          styles: {
            flexDirection: "row",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "40%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                    textAlign: "left",
                  },
                  content: "__itineraryPorts_name__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "30%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    textAlign: "left",
                  },
                  content:
                    "__itineraryPorts_arrival__ __itineraryPorts_arrivalTime__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "30%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    textAlign: "left",
                  },
                  content:
                    "__itineraryPorts_departure__ __itineraryPorts_departureTime__",
                },
              ],
            },
          ],
        },

        {
          type: "view",
          styles: {
            flexDirection: "row",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "40%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                    textAlign: "left",
                    marginBottom: 0,
                    marginTop: 15,
                  },
                  content: "Port of disembarkation",
                },
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                    textAlign: "left",
                  },
                  content: "__itineraryDismbarkationPort__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "30%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    marginBottom: 0,
                    marginTop: 15,
                  },
                  content: " ",
                },
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    textAlign: "left",
                  },
                  content:
                    "__cruiseEndDateItinerary__ __cruiseEndTimeItinerary__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "30%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    textAlign: "left",
                  },
                  content: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default jsonView;
