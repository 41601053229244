import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: "20px 50px",
    fontFamily: "Open Sans",
  },

  textContent: {
    fontSize: 10,
    marginTop: 5,
    marginBottom: 5,
  },
});

const jsonView = {
  type: "page",
  items: [
    {
      type: "view",
      styles: {
        margin: 10,
        marginLeft: 20,
        padding: 10,
      },
      items: [
        {
          type: "text",
          styles: {
            fontSize: 16,
            marginTop: 15,
            textAlign: "center",
            fontFamily: "Helvetica-Bold",
          },
          content: "PASSENGER LIST",
        },
      ],
    },

    {
      type: "view",
      attrs: { wrap: false },
      repeated: true,
      findRepeated: "passenger_firstname",
      styles: {
        margin: 10,
        marginLeft: 20,
        marginTop: 15,
        padding: 10,
        zIndex: 1,
        border: "1pt solid #000000",
      },
      items: [
        {
          type: "view",
          styles: {
            flexDirection: "row",
            justifyContent: "space-between",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "40%",
              },

              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Firstname",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    marginTop: 0,
                  },
                  content: "__passenger_firstname__",
                  renderBlank: true,
                },
              ],
            },

            {
              type: "view",

              styles: {
                width: "40%",
              },

              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Surname",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    marginTop: 0,
                  },
                  content: "__passenger_surname__",
                  renderBlank: true,
                },
              ],
            },

            {
              type: "view",

              styles: {
                width: "20%",
              },

              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Nationality",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    marginTop: 0,
                  },
                  content: "__passenger_nationality__",
                  renderBlank: true,
                },
              ],
            },
          ],
        },

        {
          type: "view",
          styles: {
            flexDirection: "row",
            justifyContent: "space-between",
          },
          items: [
            {
              type: "view",

              styles: {
                width: "40%",
              },

              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Date of birth",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    marginTop: 0,
                  },
                  content: "__passenger_birthdate__",
                  renderBlank: true,
                },
              ],
            },

            {
              type: "view",

              styles: {
                width: "40%",
              },

              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Place of birth",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    marginTop: 0,
                  },
                  content: "__passenger_birthplace__",
                  renderBlank: true,
                },
              ],
            },

            {
              type: "view",

              styles: {
                width: "20%",
              },

              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Passport/ID n°",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    marginTop: 0,
                  },
                  content: "__passenger_passport__",
                  renderBlank: true,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default jsonView;
