import { companiesService } from "../_services";

import { alertConstants } from "../_constants";
import { alertActions } from "./alert.actions";

export const fetchCompanies =
  (page = 1, name = "") =>
  async (dispatch) => {
    try {
      const companies = await companiesService.findAll(page, name);
      dispatch({
        type: "companies/itemsLoaded",
        payload: companies,
      });
    } catch (err) {
      dispatch({
        type: "companies/fetchError",
      });

      dispatch(
        alertActions.setAlert(
          "Une erreur s'est produite.",
          alertConstants.ERROR
        )
      );
    }
  };

export const fetchCompany = (id) => async (dispatch) => {
  try {
    const company = await companiesService.find(id);

    dispatch({
      type: "companies/itemLoaded",
      payload: company,
    });
  } catch (err) {
    dispatch({
      type: "companies/fetchError",
      payload: null,
    });

    dispatch(alertActions.setAlert(err, alertConstants.ERROR));
  }
};

export const emptyCompanies = () => async (dispatch) => {
  dispatch({
    type: "companies/empty",
  });
};

export const updateCompany = (id, company) => async (dispatch) => {
  try {
    const updatedCompany = await companiesService.editCompany(id, company);

    dispatch({
      type: "companies/itemLoaded",
      payload: updatedCompany,
    });

    dispatch(
      alertActions.setAlert(
        "La société a bien été mise à jour.",
        alertConstants.SUCCESS
      )
    );
  } catch (err) {
    dispatch(alertActions.setAlert(err, alertConstants.ERROR));
  }
};

export const refreshTokensCompany = (id, tokens) => async (dispatch) => {
  try {
    const updatedCompany = await companiesService.refreshTokens(id, tokens);

    dispatch({
      type: "companies/itemLoaded",
      payload: updatedCompany,
    });
  } catch (err) {
    dispatch(alertActions.setAlert(err, alertConstants.ERROR));
  }
};

export const tokenManagementCompany = (id, data) => async (dispatch) => {
  try {
    const manage = await companiesService.tokenManagementCompany(id, data);

    dispatch({
      type: "companies/itemLoaded",
      payload: manage,
    });
    dispatch(fetchCompany(id));

    dispatch(
      alertActions.setAlert(
        "La société a bien été mise à jour.",
        alertConstants.SUCCESS
      )
    );
  } catch (err) {
    dispatch(alertActions.setAlert(err, alertConstants.ERROR));
  }
};

export const chooseMainForCompany = (id, mainBroker) => async (dispatch) => {
  try {
    const manage = await companiesService.chooseMain(id, mainBroker);

    dispatch({
      type: "companies/itemLoaded",
      payload: manage,
    });
    dispatch(fetchCompany(id));

    dispatch(
      alertActions.setAlert(
        "Le broker principal a été mis à jour",
        alertConstants.SUCCESS
      )
    );
  } catch (err) {
    dispatch(alertActions.setAlert(err, alertConstants.ERROR));
  }
};
