import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { alertActions } from '../_actions'

import { AdminLayout } from '../_layouts'

const AdminRoute = ({ dispatch, auth, component: Component, ...rest }) => {
  const renderComponent = (props) => {
    let user = auth.user

    const isAdmin = (el) => el === 'ROLE_ADMIN'

    if (user.roles && user.roles.findIndex(isAdmin) !== -1) {
      return (
        <AdminLayout>
          <Component {...props} />
        </AdminLayout>
      )
    } else {
      dispatch(
        alertActions.failure(
          'Vous ne possédez pas les droits requis pour accéder à cette page.'
        )
      )

      return (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('user') ? (
          renderComponent(props)
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

const mapStateToProps = (state) => ({
  auth: state.authentication,
})

const connectedAdminRoute = connect(mapStateToProps)(AdminRoute)
export { connectedAdminRoute as AdminRoute }
