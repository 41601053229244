import axios from "axios";

import { ApiService } from "./api";

import { authHeader } from "../_helpers";

const apiService = new ApiService();
apiService.setApiUrl("/contract_templates");

export const contractsTemplateService = {
  findAll,
  find,
  add,
  addBase,
  findStandard,
  findCharter,
  findDay,
};

async function findAll(page = 1) {
  let parameters = `page=${page}&visible=true`;

  try {
    const res = await axios.get(
      `${apiService.getApiUrl()}?${parameters}`,
      authHeader()
    );

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw apiService.manageErrors(err);
  }
}

async function findStandard(folderId) {
  try {
    const res = await axios.get(
      `${apiService.getApiUrl()}/available/standard/${folderId}`,
      authHeader()
    );

    return res.data;
  } catch (err) {
    throw apiService.manageErrors(err);
  }
}

async function findCharter(folderId) {
  try {
    const res = await axios.get(
      `${apiService.getApiUrl()}/available/charter/${folderId}`,
      authHeader()
    );

    return res.data;
  } catch (err) {
    throw apiService.manageErrors(err);
  }
}

async function findDay(folderId) {
  try {
    const res = await axios.get(
      `${apiService.getApiUrl()}/available/day/${folderId}`,
      authHeader()
    );

    return res.data;
  } catch (err) {
    throw apiService.manageErrors(err);
  }
}
async function find(id) {
  return apiService.find(id);
}

async function add(template) {
  return apiService.add(template);
}

async function addBase(template) {
  return apiService.addBase(template);
}
