import React, { useState } from "react";

import { Button, Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";

import { Redirect } from "react-router-dom";

import { UpdatePasswordModal } from "./modals/UpdatePasswordModal";
import { GenerateKeysModal } from "./modals/GenerateKeysModal";

import { alertActions } from "../_actions";
import { alertConstants } from "../_constants";

import { userActions } from "../_actions";

import { userService, brokersService } from "../_services";

import { history } from "../_helpers";

export const FirstConnection = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);

  const [isPasswordModalShown, setPasswordModalShown] = useState(false);
  const [isGenerateKeysModalShown, setGenerateKeysModalShown] = useState(
    user?.passwordUpdated
  );

  const onBeginClick = () => {
    setPasswordModalShown(true);
  };

  const onModalClose = () => {
    setPasswordModalShown(false);
  };

  const onModalSubmit = async (password) => {
    const updatedUser = {
      id: user.id,
      password: password,
    };

    try {
      const res = await userService.update(updatedUser);

      const updatedBroker = {
        id: user.id,
        passwordUpdated: true,
        firstConnection: false,
        resetAsked: true
      };

      const modified = await brokersService.update(updatedBroker);

      dispatch(
        alertActions.setAlert(
          "Votre mot de passe a bien été modifié.",
          alertConstants.SUCCESS
        )
      );

      dispatch(userActions.fetchBrokerUser(user));

      setPasswordModalShown(false);
      setGenerateKeysModalShown(true);
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }
  };

  const onKeysModalClose = () => {
    setGenerateKeysModalShown(false);
  };

  const openKeysModal = () => {
    setGenerateKeysModalShown(true);
  };

  const onKeysModalSubmit = async () => {
    try {
      const updatedBroker = {
        id: user.id,
        firstConnection: false,
      };

      const res = await brokersService.update(updatedBroker);

      dispatch(
        alertActions.setAlert(
          "Vous pouvez désormais utiliser la plateforme.",
          alertConstants.SUCCESS
        )
      );

      dispatch(userActions.fetchBrokerUser(user));

      history.push("/");
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }

    setGenerateKeysModalShown(false);
  };

  return (
    <>
      <UpdatePasswordModal
        isShown={isPasswordModalShown}
        handleClose={onModalClose}
        handleSubmit={onModalSubmit}
      />

      <GenerateKeysModal
        isShown={isGenerateKeysModalShown}
        handleClose={onKeysModalClose}
        handleSubmit={onKeysModalSubmit}
      />

      {user && !user.firstConnection && !user.resetAsked && (
        <Redirect to={{ pathname: "/" }} />
      )}

      <h1 className="page-title">Première connexion</h1>

      <p>
        Ceci est votre première connexion à notre plateforme. Afin de sécuriser
        vos échanges, vous allez devoir modifier votre mot de passe, et générer
        vos clés de chiffrement.
      </p>

      {user && user.firstConnection && !user.passwordUpdated && (
        <Button variant="primary" onClick={onBeginClick}>
          Commencer
        </Button>
      )}
    </>
  );
};
