import { contractsTemplateService } from '../_services'

import { alertConstants } from '../_constants'
import { alertActions } from './alert.actions'

export const fetchContractTemplates = (page = 1) => async (dispatch) => {
  try {
    const templates = await contractsTemplateService.findAll(page)

    dispatch({
      type: 'contractTemplates/itemsLoaded',
      payload: templates,
    })
  } catch (err) {
    dispatch({ type: 'contractTemplates/fetchError' })
    dispatch(alertActions.setAlert(err, alertConstants.ERROR))
  }
}

export const fetchContractTemplate = (id) => async (dispatch) => {
  try {
    const template = await contractsTemplateService.find(id)

    dispatch({
      type: 'contractTemplates/itemLoaded',
      payload: template,
    })
  } catch (err) {
    dispatch({ type: 'contractTemplates/fetchError' })
    dispatch(alertActions.setAlert(err, alertConstants.ERROR))
  }
}
