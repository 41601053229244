import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  privateKey: '',
}

export const cryptKeysSlice = createSlice({
  name: 'cryptKeys',
  initialState,
  reducers: {
    privateLoaded: (state, action) => {
      return {
        ...state,
        privateKey: action.payload,
      }
    },

    privateCleared: (state, action) => {
      return {
        ...state,
        privateKey: '',
      }
    },
  },
})

export const { privateLoaded, privateCleared } = cryptKeysSlice.actions
export default cryptKeysSlice.reducer
