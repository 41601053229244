export const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  AUTH_ERROR: 'AUTH_ERROR',
  AUTH_SUCCESS: 'AUTH_SUCCESS',

  USER_UPDATE: 'USER_UPDATE',
}
