import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Button } from 'react-bootstrap'

import { fetchContractTemplates } from '../../_actions'

export const ContractIndex = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const templates = useSelector((state) => state.contractTemplates.items)

  useEffect(() => {
    dispatch(fetchContractTemplates())
  }, [])

  return (
    <>
      <h1 className='page-title'>{t('Contracts management')}</h1>

      <h2 className='page-subtitle'>{t('Templates list')}</h2>

      {templates.map((template) => (
        <p key={template.id}>
          <strong>{template.name}</strong> -{' '}
          <Link to={`/admin/contracts/template/${template.id}`}>
            <Button variant='primary'>{t('Edit')}</Button>
          </Link>
        </p>
      ))}
    </>
  )
}
