import React, { useEffect, useState } from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import { encrypt, decrypt } from "../_helpers";

import openSans from "./fonts/OpenSans-Regular.ttf";
import openSansItalic from "./fonts/OpenSans-Italic.ttf";
import openSansBold from "./fonts/OpenSans-Bold.ttf";

import arial from "./fonts/ArialCE.ttf";
import arialItalic from "./fonts/ArialCEItalic.ttf";
import arialBold from "./fonts/ArialCEBold.ttf";

import ecpyLogo from "./images/ecpy.jpg";
import ecpyTransparentLogo from "./images/ecpy.png";

import mentionsFr1 from "./images/CS-FR/Conditions-p1-Transport - Contrat V3.0_ECPY_FR_formulaire_rev.docx - copie.jpg";
import mentionsFr2 from "./images/CS-FR/Conditions-p2-Transport - Contrat V3.0_ECPY_FR_formulaire_rev.docx - copie.jpg";
import mentionsFr3 from "./images/CS-FR/Conditions-p3-Transport - Contrat V3.0_ECPY_FR_formulaire_rev.docx - copie.jpg";
import mentionsFr4 from "./images/CS-FR/Conditions-p4-Transport - Contrat V3.0_ECPY_FR_formulaire_rev.docx - copie.jpg";

import mentionsEn1 from "./images/CS-EN/Conditions-p1-Transport - Agreement V3.0_ECPY_EN_form with WatrMk&CopyRg.docx - copie.jpg";
import mentionsEn2 from "./images/CS-EN/Conditions-p2-Transport - Agreement V3.0_ECPY_EN_form with WatrMk&CopyRg.docx - copie.jpg";
import mentionsEn3 from "./images/CS-EN/Conditions-p3-Transport - Agreement V3.0_ECPY_EN_form with WatrMk&CopyRg.docx - copie.jpg";
import mentionsEn4 from "./images/CS-EN/Conditions-p4-Transport - Agreement V3.0_ECPY_EN_form with WatrMk&CopyRg.docx - copie.jpg";

import dayMentions1 from "./images/CJ-FR-EN/Conditions-p1-Transport - Contrat Journée- Day Agreement V3.0_ECPY_form.docx - copie.jpg";
import dayMentions2 from "./images/CJ-FR-EN/Conditions-p2-Transport - Contrat Journée- Day Agreement V3.0_ECPY_form.docx - copie.jpg";
import dayMentions3 from "./images/CJ-FR-EN/Conditions-p3-Transport - Contrat Journée- Day Agreement V3.0_ECPY_form.docx - copie.jpg";
import dayMentions4 from "./images/CJ-FR-EN/Conditions-p4-Transport - Contrat Journée- Day Agreement V3.0_ECPY_form.docx - copie.jpg";

import CharterMentions1 from "./images/CDC-EN-notices-P1.jpg";
import CharterMentions2 from "./images/CDC-EN-notices-P2.jpg";
import CharterMentions3 from "./images/CDC-EN-notices-P3.jpg";
import CharterMentions4 from "./images/CDC-EN-notices-P4.jpg";

import CharterMentions1fr from "./images/CDC-Mentions1-FR.jpg";
import CharterMentions2fr from "./images/CDC-Mentions2-FR.jpg";
import CharterMentions3fr from "./images/CDC-Mentions3-FR.jpg";
import CharterMentions4fr from "./images/CDC-Mentions4-FR.jpg";

import pdfStyles from "./views/ContractStyles";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: openSans,
    },
    {
      src: openSansItalic,
      fontStyle: "italic",
    },
    {
      src: openSansBold,
      fontWeight: 800,
    },
  ],
});

Font.register({
  family: "Arial",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialItalic,
      fontStyle: "italic",
    },
    {
      src: arialBold,
      fontWeight: 800,
    },
  ],
});

export const ContractDocument = ({
  contract,
  jsonView,
  type,
  status,
  lang,
  user = null,
  codename,
}) => {
  const { t, i18n } = useTranslation();

  const logoUrl = `${process.env.REACT_APP_API_ENTRYPOINT}/ecpy.jpg`;

  const extractField = (id) => {
    if (!contract.fields[id]) {
      return "";
    }

    return contract.fields[id].value;
  };

  const extractFieldByName = (name) => {
    let field = null;

    for (let i in contract.fields) {
      if (contract.fields[i].name === name) {
        if (!field || (field && contract.fields[i].order < field.order)) {
          field = contract.fields[i];
        }
      }
    }

    return field;
  };

  const extractFieldValueByName = (name) => {
    let field = null;

    let savedIndex = null;

    for (let i in contract.fields) {
      if (contract.fields[i].name === name) {
        if (!field || (field && contract.fields[i].order < field.order)) {
          field = contract.fields[i];
          savedIndex = i;
        }
      }
    }

    // TODO : faire ça propre pour les champs à ne pas supprimer
    if (
      field &&
      savedIndex &&
      field.name !== "mainCurrency" &&
      field.name !== "currencyMain" &&
      field.name !== "addendumCurrency" &&
      field.name !== "client" &&
      field.name !== "serviceProvider" &&
      field.name !== "brokerName" &&
      field.name !== "centralAgent" &&
      field.name !== "stakeholder" &&
      field.name !== "agreementUniqueNumber"
    ) {
      delete contract.fields[savedIndex];
    }

    if (field) {
      // Checkbox cases
      if (field.value === "true") {
        return t(field.label);
      } else if (field.value === "false") {
        return "";
      }

      // default (text / date / radio / ...)
      return field.value;
    }

    return "";
  };

  const replaceVariables = (view, specialType = null) => {
    const locale = contract.contractFolder.lang;

    const numberLocale = "en-US";
    const opts = { minimumFractionDigits: 2 };

    let updatedView = view;

    const dataRegex = /__[\s\S]*__/g;
    //const idToReplaceRegex = /(?<=__)[\S]*?(?=__)/g
    const idToReplaceRegex = /(?:__)([\S]*?)(?=__)/g;

    const splitRegex = /__[\S]*?__/g;
    const splitViewElements = updatedView.split(splitRegex);

    // Translate labels

    for (let el of splitViewElements) {
      if (el !== "") {
        const splitted = el.split(/\s:\s/);

        for (let splitEl of splitted) {
          if (splitEl !== "") {
            const trimEl = splitEl.trim();

            const translated =
              contract.template.codeName === "baseTemplateDay"
                ? trimEl
                : t(trimEl, { lng: locale });

            updatedView = updatedView.replaceAll(trimEl, translated);
          }
        }
      }
    }

    // Replace variables

    const idsToReplace = updatedView.match(idToReplaceRegex);

    if (idsToReplace) {
      for (let id of idsToReplace) {
        const goodId = id.substring(2);

        const replaceRegex = new RegExp(`__${goodId}__`, "g");

        let tempData = "";

        var fieldName = goodId;

        if (goodId === "clientAdresse1") {
          fieldName = "clientAddress1";
        }

        if (goodId === "clientAdresse2") {
          fieldName = "clientAddress2";
        }

        if (goodId === "clientAdresse3") {
          fieldName = "clientAddress3";
        }

        let field = extractFieldByName(fieldName);

        if (specialType && specialType === "display-everytime") {
          if (field) {
            tempData = t(field.label);
          }
        }

        let data = extractFieldValueByName(fieldName);
        if (data === "") {
          // If no data, replace with label
          data = tempData;
        }

        if (!isNaN(data) && !isNaN(parseFloat(data))) {
          if (field.formatNumber) {
            const nb = parseFloat(data);
            data = nb.toLocaleString(numberLocale, opts);
          }
        }

        if (data === "Invalid date") {
          data = "";
        }

        if (
          goodId === "additionalInsuranceLuggage" ||
          goodId === "additionalInsuranceAssistance" ||
          goodId === "additionalInsuranceCancellation"
        ) {
          data = t(data, { lng: locale });
        }

        updatedView = updatedView.replaceAll(replaceRegex, data);
      }
    }

    return updatedView;
  };

  const renderNode = (node) => {
    let ignoreField = false;

    if (node.type === "text") {
      if (
        node.content.includes("Second instalment") ||
        node.content.includes("Deuxième versement")
      ) {
        const field = extractFieldByName("secondInstalment");

        if (!field || field.value === "") {
          ignoreField = true;
        }
      }

      if (
        node.content.includes("Third instalment") ||
        node.content.includes("Troisième versement")
      ) {
        const field = extractFieldByName("thirdInstalment");

        if (!field || field.value === "") {
          ignoreField = true;
        }
      }

      if (
        node.content.includes("Date of agreement") ||
        node.content.includes("Date du contrat")
      ) {
        /*if (contract.contractFolder.status === "draft") {
          ignoreField = true;
        }*/
      }

      let specialType = node.specialType ? node.specialType : null;
      let content = replaceVariables(node.content, node.specialType);

      if ((content !== "" || node.renderBlank === true) && !ignoreField) {
        return (
          <Text style={node.styles ? node.styles : {}}>
            {content} {node.items && <> {renderItems(node.items)}</>}
          </Text>
        );
      }
    }
  };

  const renderView = (item) => {
    let styles = item.styles ? item.styles : {};
    let items = item.items;

    if (item.specialType) {
      if (item.specialType === "checkbox") {
        let linkedFieldName = item.linkedField;
        let field = extractFieldByName(linkedFieldName);

        if (field && field.value === "true") {
          styles = {
            ...styles,
            ...pdfStyles.checkboxChecked,
            backgroundColor: "#000",
          };
        }
      } else if (item.specialType === "choice") {
        items = [];

        let linkedFieldName = item.linkedField;
        let field = extractFieldByName(linkedFieldName);

        if (field) {
          let fieldValue = field.value;

          if (
            field.options &&
            field.options?.length &&
            field.options?.length > 0
          ) {
            for (let option of field.options) {
              let checkboxStyles = { ...pdfStyles.checkbox };

              if (option === fieldValue) {
                checkboxStyles = {
                  ...checkboxStyles,
                  ...pdfStyles.checkboxChecked,
                };
              }

              let optionItem = {
                type: "view",
                styles: {
                  ...pdfStyles.checkboxWrapper,
                  marginRight: "5pt",
                },
                items: [
                  {
                    type: "view",
                    styles: {
                      ...checkboxStyles,
                    },
                    items: [],
                  },

                  {
                    type: "text",
                    styles: {
                      ...pdfStyles.textContent,
                      paddingTop: item.withPadding ? "3pt" : "0",
                    },
                    content: option,
                  },
                ],
              };

              items.push(optionItem);
            }
          }
        }
      }
    }

    return (
      <View
        style={styles}
        break={item.attrs && item.attrs.break ? true : false}
        wrap={item.attrs && item.attrs.wrap === false ? false : true}
      >
        {renderItems(items)}
      </View>
    );
  };

  const renderItems = (items) => {
    return items.map((item, index) => (
      <React.Fragment key={index}>
        {item.type === "view" && (
          <>
            {item.repeated ? (
              <>{renderRepeatedViewItem(item)}</>
            ) : (
              <>{renderView(item)}</>
            )}
          </>
        )}

        {item.type === "text" && renderNode(item)}
      </React.Fragment>
    ));
  };

  const renderRepeatedViewItem = (item) => {
    // Find how many
    let nameToFind = item.findRepeated;
    let repeatNb = 0;

    for (let i in contract.fields) {
      if (contract.fields[i].name === nameToFind) {
        repeatNb++;
      }
    }

    return Array.from({ length: repeatNb }, (_, k) => (
      <View
        style={item.styles ? item.styles : {}}
        break={item.attrs && item.attrs.break ? true : false}
        wrap={contract.template?.codeName === "passengers" && false}
        key={k}
      >
        {renderItems(item.items)}
      </View>
    ));
  };

  const renderFromJson = (json) => {
    if (json.type === "page") {
      return renderItems(json.items);
    }
  };

  const renderTopInfos = () => {
    if (!contract.template) {
    }

    const idField = extractFieldByName("agreementUniqueNumber");

    if (idField) {
      const value = idField.value;

      return (
        <Text
          fixed
          style={{
            ...pdfStyles.textContent,
            fontSize: 8,
            position: "absolute",
            top: 15,
            textAlign: "center",
          }}
        >
          {contract.contractFolder?.folderType === "transport"
            ? t("Transport Agreement n°")
            : t("Charter Agreement n°")}{" "}
          {value}
        </Text>
      );
    }
  };

  return (
    <Page size="A4" style={pdfStyles.page}>
      {contract !== "legalMentions" && contract !== "legalMentionsFr" && (
        <Image
          fixed
          src={ecpyTransparentLogo}
          style={{
            width: 65,
            height: "auto",
            position: "absolute",
            top: 10,
            left: -5,
          }}
        />
      )}

      {renderTopInfos()}

      {contract !== "legalMentions" && contract !== "legalMentionsFr" && (
        <Image
          src={ecpyLogo}
          style={{
            width: 100,
            height: "auto",
            position: "absolute",
            top: 10,
            left: 0,
          }}
        />
      )}

      {contract !== "legalMentions" && contract !== "legalMentionsFr" && (
        <Text
          fixed
          style={{
            fontFamily: "Open Sans",
            fontSize: 96,
            color: "#000000",
            opacity: 0.3,
            transform: "rotate(-45deg)",
            position: "absolute",
            left: status === "draft" ? 0 : -120,
            top: 200,
            width: 1000,
            zIndex: 100,
          }}
        >
          {status === "draft"
            ? t("DRAFT", { lng: lang })
            : t("ECPY COPYRIGHT", { lng: lang })}
        </Text>
      )}

      {contract.template?.codeName === "passengers" && (
        <View
          fixed
          style={{
            position: "absolute",
            bottom: 10,
          }}
        >
          <Text style={{ ...pdfStyles.textContent, textAlign: "center" }}>
            {t("PLEASE INITIAL", { lng: contract.contractFolder?.lang })} :{" "}
            {t("SERVICE PROVIDER", { lng: contract.contractFolder?.lang })}{" "}
            __________ {t("CLIENT", { lng: contract.contractFolder?.lang })}{" "}
            __________
          </Text>
        </View>
      )}

      {contract.template?.codeName === "rider" && (
        <View
          fixed
          style={{
            position: "absolute",
            bottom: 50,
          }}
        >
          <Text style={{ ...pdfStyles.textContent, textAlign: "center" }}>
            {t("PLEASE INITIAL", { lng: contract.contractFolder?.lang })} :{" "}
            {t("SERVICE PROVIDER", { lng: contract.contractFolder?.lang })}{" "}
            __________ {t("CLIENT", { lng: contract.contractFolder?.lang })}{" "}
            __________
          </Text>
        </View>
      )}

      {contract.template?.codeName === "itinerary" && (
        <View
          fixed
          style={{
            position: "absolute",
            bottom: 50,
          }}
        >
          <Text style={{ ...pdfStyles.textContent, textAlign: "center" }}>
            {t("Client-Captain agreement on detailed itinerary", {
              lng: contract.contractFolder?.lang,
            })}{" "}
            : {t("CAPTAIN", { lng: contract.contractFolder?.lang })} __________{" "}
            {t("CLIENT", { lng: contract.contractFolder?.lang })} __________
          </Text>
        </View>
      )}

      {contract.template?.codeName === "baseTemplateDay" &&
        contract.template !== undefined && (
          <View
            fixed
            style={{
              position: "absolute",
              bottom: 50,
              left: 65,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View style={{ width: "30%" }}>
              <Text
                style={{
                  ...pdfStyles.textContent,
                }}
              >
                {t("PLEASE INITIAL")}
              </Text>

              {
                <Text
                  style={{
                    ...pdfStyles.textContent,
                    fontSize: 7,
                    fontStyle: "italic",
                    marginTop: 0,
                  }}
                >
                  {t("PLEASE INITIAL")}
                </Text>
              }
            </View>

            <View style={{ width: "30%" }}>
              <Text
                style={{
                  ...pdfStyles.textContent,
                }}
              >
                {t("PROPRIETAIRE")} _{" "}
              </Text>
              {
                <Text
                  style={{
                    ...pdfStyles.textContent,
                    fontSize: 7,
                    fontStyle: "italic",
                    marginTop: 0,
                  }}
                >
                  {t("SERVICE PROVIDER")}
                </Text>
              }
            </View>

            <View style={{ width: "30%" }}>
              <Text
                style={{
                  ...pdfStyles.textContent,
                }}
              >
                {t("AFFRÉTEUR")} __
              </Text>
              {
                <Text
                  style={{
                    ...pdfStyles.textContent,
                    fontSize: 7,
                    fontStyle: "italic",
                    marginTop: 0,
                  }}
                >
                  {t("CLIENT")}
                </Text>
              }
            </View>
          </View>
        )}

      <Text
        fixed
        style={{
          fontFamily: "Open Sans",
          fontSize: 8,
          fontStyle: "italic",
          color: "#203f80",
          transform: "rotate(-90deg)",
          position: "absolute",
          top: 400,
          right: contract.template?.codeName === "baseTemplateDay" ? 170 : 180,
          width: 800,
        }}
      >
        {contract !== "legalMentions" &&
          contract !== "legalMentionsFr" &&
          t(
            "This Agreement is the property of ECPY. Any reproduction, in part or in full, any distribution or use without the formal agreement in writing of this Association is strictly forbidden. All rights reserved.",
            {
              lng:
                contract.template?.codeName === "baseTemplateDay"
                  ? "en"
                  : contract.contractFolder?.lang,
            }
          )}
      </Text>

      <Text
        fixed
        style={{
          fontFamily: "Arial",
          fontSize: 8,
          position: "absolute",
          top: 10,
          right: 10,
        }}
        render={({ pageNumber }) =>
          `Page ${
            ["passengers", "rider", "addendum", "itinerary"].includes(
              contract.template?.codeName
            )
              ? contract.contractFolder.contracts.map(
                  (c) => c.template?.codeName === contract.template?.codeName
                ) && pageNumber++
              : pageNumber
          }`
        }
      />

      {contract === "legalMentions" || contract === "legalMentionsFr" ? (
        <>
          {contract === "legalMentions" &&
          type !== "charter" &&
          codename !== "baseTemplateDay" ? (
            <>
              <View>
                <Image src={mentionsEn1} style={{ marginTop: 25 }} />
                <Image src={mentionsEn2} style={{ marginTop: 25 }} />
                <Image src={mentionsEn3} style={{ marginTop: 25 }} />
                <Image src={mentionsEn4} style={{ marginTop: 25 }} />
                <Text
                  fixed
                  style={{
                    fontFamily: "Open Sans",
                    fontSize: 96,
                    color: "#000000",
                    opacity: 0.3,
                    transform: "rotate(-45deg)",
                    position: "absolute",
                    left: status === "draft" ? -30 : -170,
                    top: 200,
                    width: 1000,
                    zIndex: 100,
                  }}
                >
                  {status === "draft"
                    ? t("DRAFT", { lng: lang })
                    : t("ECPY COPYRIGHT", { lng: lang })}
                </Text>
              </View>
            </>
          ) : contract === "legalMentionsFr" &&
            type !== "charter" &&
            codename !== "baseTemplateDay" ? (
            <View>
              <Image src={mentionsFr1} style={{ marginTop: 25 }} />
              <Image src={mentionsFr2} style={{ marginTop: 25 }} />
              <Image src={mentionsFr3} style={{ marginTop: 25 }} />
              <Image src={mentionsFr4} style={{ marginTop: 25 }} />
              <Text
                fixed
                style={{
                  fontFamily: "Open Sans",
                  fontSize: 96,
                  color: "#000000",
                  opacity: 0.3,
                  transform: "rotate(-45deg)",
                  position: "absolute",
                  left: status === "draft" ? -30 : -170,
                  top: 200,
                  width: 1000,
                  zIndex: 100,
                }}
              >
                {status === "draft"
                  ? t("DRAFT", { lng: lang })
                  : t("ECPY COPYRIGHT", { lng: lang })}
              </Text>
            </View>
          ) : type !== "charter" ? (
            <View>
              <Image src={dayMentions1} style={{ marginTop: 10 }} />
              <Image src={dayMentions2} style={{ marginTop: 10 }} />
              <Image src={dayMentions3} style={{ marginTop: 10 }} />
              <Image src={dayMentions4} style={{ marginTop: 10 }} />
              <Text
                fixed
                style={{
                  fontFamily: "Open Sans",
                  fontSize: 96,
                  color: "#000000",
                  opacity: 0.3,
                  transform: "rotate(-45deg)",
                  position: "absolute",
                  left: status === "draft" ? -30 : -170,
                  top: 200,
                  width: 1000,
                  zIndex: 100,
                }}
              >
                {status === "draft"
                  ? t("DRAFT", { lng: lang })
                  : t("ECPY COPYRIGHT", { lng: lang })}
              </Text>
            </View>
          ) : (
            <View>
              <Image
                src={lang === "fr" ? CharterMentions1fr : CharterMentions1}
                style={{ marginTop: 25 }}
              />
              <Image
                src={lang === "fr" ? CharterMentions2fr : CharterMentions2}
                style={{ marginTop: 25 }}
              />

              <Image
                src={lang === "fr" ? CharterMentions3fr : CharterMentions3}
                style={{ marginTop: 25 }}
              />
              <Image
                src={lang === "fr" ? CharterMentions4fr : CharterMentions4}
                style={{ marginTop: 25 }}
              />
              <Text
                fixed
                style={{
                  fontFamily: "Open Sans",
                  fontSize: 96,
                  color: "#000000",
                  opacity: 0.25,
                  transform: "rotate(-45deg)",
                  position: "absolute",
                  left: status === "draft" ? -30 : -170,
                  top: 200,
                  width: 1000,
                  zIndex: 100,
                }}
              >
                {status === "draft"
                  ? t("DRAFT", { lng: lang })
                  : t("ECPY COPYRIGHT", { lng: lang })}
              </Text>
            </View>
          )}
        </>
      ) : (
        <>
          {renderFromJson(jsonView)}

          {/* {contract.template.codeName === "agencyAgreement" &&
            type !== "charter" && (
              <>
                {contract.contractFolder?.lang === "fr" ? (
                  <View
                    break
                    style={{
                      width: "100%",
                      height: "100%",
                      paddingTop: 25,
                      position: "relative",
                      zIndex: 500,
                    }}
                  >
                    <Image src={agreementFr} />
                  </View>
                ) : (
                  <View
                    break
                    style={{
                      width: "100%",
                      height: "100%",
                      paddingTop: 25,
                      position: "relative",
                      zIndex: 500,
                    }}
                  >
                    <Image src={agreementEn} />
                  </View>
                )}
              </>
            )} */}
        </>
      )}
    </Page>
  );
};
