import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import { connectRouter } from 'connected-react-router'
import { history } from '../_helpers/history'

import { authentication } from './authentication.reducer'
import { alert } from './alert.reducer'
import { brokers } from './brokers.reducer'

import companies from './companies.reducer'
import libraryDocs from './libraryDocs.reducer'
import contracts from './contracts.reducer'
import cryptKeys from './cryptKeys.reducer'
import contractTemplates from './contractTemplates.reducer'
import contractFolders from './contractFolders.reducer'
import contractsHistories from './contractsHistories.reducer'

const rootReducer = combineReducers({
  router: connectRouter(history),
  form,
  authentication,
  alert,
  brokers,
  companies,
  libraryDocs,
  contracts,
  cryptKeys,
  contractTemplates,
  contractFolders,
  contractsHistories,
})

export default rootReducer
