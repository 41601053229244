import axios from "axios";

import { authHeader } from "../_helpers";
import { updateCompany } from "../_actions";

const API_URL = `${process.env.REACT_APP_API_ENTRYPOINT}/companies`;

export const companiesService = {
  findAll,
  findAllNoPagination,
  find,
  add,
  update,
  remove,
  refreshTokens,
  editCompany,
  tokenManagementCompany,
  chooseMain,
};

function manageErrors(err) {
  if (err.response?.data) {
    const type = err.response.data["@type"];
    if (type) {
      return err.response.data["hydra:description"];
    } else {
      return err.response.data.message;
    }
  }

  return err;
}

async function findAll(page = 1, name = "") {
  let parameters = `page=${page}`;
  if (name != "") {
    parameters += `&name=${name}`;
  }
  try {
    const res = await axios.get(`${API_URL}?${parameters}`, authHeader());

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw manageErrors(err);
  }
}

async function findAllNoPagination() {
  let parameters = `pagination=false`;

  try {
    const res = await axios.get(`${API_URL}?${parameters}`, authHeader());

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw manageErrors(err);
  }
}

async function find(id) {
  try {
    const res = await axios.get(`${API_URL}/${id}`, authHeader());

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw manageErrors(err);
  }
}

async function add(
  name,
  address1,
  address2,
  zipCode,
  city,
  country,
  MYBAid,
  registrationNumber,
  registrationCountry,
  VATnumber,
  insurance,
  financialGuarantee,
  ecpyId,
  cdtPermit,
  cdcPermit
) {
  try {
    const body = JSON.stringify({
      name,
      address1,
      address2,
      zipCode,
      city,
      country,
      MYBAid,
      registrationNumber,
      registrationCountry,
      VATnumber,
      insurance,
      financialGuarantee,
      ecpyId,
      cdtPermit,
      cdcPermit,
    });

    const res = await axios.post(`${API_URL}`, body, authHeader());

    if (res.status == 201) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw manageErrors(err);
  }
}

async function update(company) {
  try {
    let companyToUpdate = company;
    if (company.mainBroker) {
      companyToUpdate.mainBroker = `/brokers/${company.mainBroker.id}`;
    }

    if (company.ecpyId) {
      company.ecpyId = parseInt(company.ecpyId);
    }

    const body = JSON.stringify(companyToUpdate);
    const res = await axios.put(`${API_URL}/${company.id}`, body, authHeader());

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw manageErrors(err);
  }
}

async function remove(id) {
  try {
    const res = await axios.delete(`${API_URL}/${id}`, authHeader());

    if (res.status === 204) {
      return true;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw manageErrors(err);
  }
}

async function refreshTokens(id, tokens) {
  try {
    const data = JSON.stringify(tokens);
    await axios.put(`${API_URL}/refresh-tokens/${id}`, data, authHeader());

    return true;
  } catch (err) {
    throw manageErrors(err);
  }
}

async function editCompany(id, formData) {
  try {
    const data = JSON.stringify(formData);
    await axios.put(`${API_URL}/edit/${id}`, data, authHeader());

    return true;
  } catch (err) {
    throw manageErrors(err);
  }
}

async function tokenManagementCompany(id, formData) {
  try {
    const data = JSON.stringify(formData);
    await axios.put(`${API_URL}/token/management/${id}`, data, authHeader());

    return true;
  } catch (err) {
    throw manageErrors(err);
  }
}

async function chooseMain(id, mainBroker) {
  try {
    const data = JSON.stringify(mainBroker);
    await axios.patch(`${API_URL}/choose-main/${id}`, data, authHeader());

    return true;
  } catch (err) {
    throw manageErrors(err);
  }
}
