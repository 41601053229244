import React, { useState } from 'react'

import { Modal, Form, Button, Alert } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'

export const UpdatePasswordModal = ({ isShown, handleClose, handleSubmit }) => {
  const { t } = useTranslation()

  const [formData, setFormData] = useState({
    password: '',
    passwordCheck: '',
  })

  const { password, passwordCheck } = formData

  const [formErrors, setFormErrors] = useState([])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    // First, reset errors
    let errors = []

    if (password !== passwordCheck) {
      errors.push(t('Passwords have to be the same'))
    } else {
      if (password.length < 8) {
        errors.push(t('Your password should have 8 characters minimum'))
      }

      let specialCharsRegExp = new RegExp('(?=.*[!@#$%^&*])')
      if (!specialCharsRegExp.test(password)) {
        errors.push(
          t('Your password should contain at least one of this characters') +
            ' : ! @ # $ % ^ & *'
        )
      }

      let numbersRegExp = new RegExp('(?=.*[0-9])')
      if (!numbersRegExp.test(password)) {
        errors.push(t('Your password should contain at least one digit'))
      }
    }

    setFormErrors(errors)

    if (errors.length == 0) {
      handleSubmit(password)
    }
  }

  return (
    <Modal show={isShown} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('Update your password')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          {t(
            'Your password should have at least 8 characters, with at least 1 digit and 1 special character'
          )}
        </p>

        {formErrors.length > 0 &&
          formErrors.map((error, index) => (
            <Alert key={index} variant='danger'>
              {error}
            </Alert>
          ))}

        <Form onSubmit={handleFormSubmit}>
          <Form.Group controlId='brokerPassword'>
            <Form.Label>{t('New password')}</Form.Label>
            <Form.Control
              type='password'
              placeholder={t('8 characters mini, 1 digit, 1 special character')}
              name='password'
              value={password}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId='brokerPasswordCheck'>
            <Form.Label>{t('Confirm password')}</Form.Label>
            <Form.Control
              type='password'
              placeholder={t('Passwords have to be the same')}
              name='passwordCheck'
              value={passwordCheck}
              onChange={handleChange}
            />
          </Form.Group>

          <Button variant='warning' type='submit'>
            {t('Save')}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
